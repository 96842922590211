import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";
import { ListGroup, Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, decreaseCart, clearCart, getTotals, increaseCart, setQty } from "../../features/cartSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Cart(){

    useEffect(() =>{
        document.title = 'Корзина - Интернет магазин TGR Market';
    }, [])

    const cart = useSelector ((state) => state.cart);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTotals());
    }, [cart, dispatch])

    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem));
    }

    const handleDecreaseCart = (cartItem, qty) => {
        const newItem = {...cartItem, quantity: qty};                
        dispatch(decreaseCart(newItem))
    }

    const handleIncreaseCart = (cartItem, cartQty) => {
        const newItem = {...cartItem, cartQty: cartQty};
        dispatch(increaseCart(newItem));
    }

    const handleClearCart = () => { 
        dispatch(clearCart());
    }

    const handleSetQty = (cartItem, cartQty) => {
        const newItem = {...cartItem, cartQty: cartQty};
        dispatch(setQty(newItem));
    }

    return (
    <div className="root">
        <main>
            <Header />
            <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Корзина</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li>Корзина</li>                               
                        </ul>
                    </div>
                    <div className="cartContentWrap">
                        <ListGroup>
                            {cart.cartItems.length === 0 ? (
                                <div className="cart-empty">
                                    <p>Ваша корзина пуста!</p>
                                    <i className="fas fa-cart-arrow-down" aria-hidden="true"></i>
                                    <Link to="/">Начать покупки</Link>
                                </div>
                            ):(
                                <div>
                                    {
                                        cart.cartItems?.map(cartItem=>{                                           

                                            return(
                                                <ListGroup.Item key={cartItem.id} >
                                                    <Row>
                                                        <Col md={1}>
                                                            <Link to={`/catalog/${cartItem.searchCategory.slug}/${cartItem.slug}`}>
                                                                <Image src={`uploads/products/${cartItem.image}`} alt={cartItem.name} fluid rounded />
                                                            </Link>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Link to={`/catalog/${cartItem.searchCategory.slug}/${cartItem.slug}`}><h3>{cartItem.name}</h3></Link>                                                            
                                                        </Col>
                                                        <Col md={1}>
                                                        {cartItem.selling_price
                                                        ?
                                                        <div>
                                                            <div className="cartProdPrice">{cartItem.selling_price} ₸ / шт.</div>                                                            
                                                            <div className="cartProdPriceSale">{cartItem.price} ₸ / шт.</div>
                                                        </div>
                                                        :
                                                        <div className="cartProdPrice">{cartItem.price} ₸ / шт.</div>
                                                        }
                                                        </Col>
                                                        <Col md={2}>
                                                            <div className="cartProdQty cartProdQuantity">
                                                                <button className="cartMinus" onClick={() => handleDecreaseCart(cartItem)}>-</button>
                                                                <input className="cartCountInp" disabled value={cartItem.cartQuantity} type={'number'} />
                                                                <button className="cartPlus" onClick={() => handleIncreaseCart(cartItem, cartItem.unitType)}>+</button>
                                                            </div>
                                                            <div className='cartUnitType'>
                                                                {cartItem.unit_up === null ? '' :<button className={`${cartItem.unit_up === cartItem.whichUnit ? 'active' : ''}`} onClick={() => handleSetQty(cartItem, cartItem.unit_up)}>уп. ({cartItem.unit_up} шт.)</button>}
                                                                {cartItem.unit_kor === null ? '' : <button className={`${cartItem.unit_kor === cartItem.whichUnit ? 'active' : ''}`} onClick={() =>  handleSetQty(cartItem, cartItem.unit_kor)}>кор. ({cartItem.unit_kor} шт.)</button>}
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Button
                                                            type="button"
                                                            variant="danger"
                                                            onClick={() => handleRemoveFromCart(cartItem)}
                                                            ><i className="fas fa-trash-alt"></i></Button>
                                                        </Col>
                                                        <Col md={2}>
                                                            {cartItem.selling_price
                                                            ?
                                                            <div className="cartProdTotalPrice">{parseInt(cartItem.selling_price * cartItem.cartQuantity)} ₸</div>
                                                            :
                                                            <div className="cartProdTotalPrice">{parseInt(cartItem.price * cartItem.cartQuantity)} ₸</div>
                                                            }                                                            
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                    
                                    <div className="cartSummaryWrap">                        
                                        <span className="cartSummaryTotal">Итого: {cart.cartTotalAmount} ₸</span>
                                        <div className="cartSummaryBtns">
                                            <button variant="info"  onClick={() => handleClearCart()} >Очистить корзину</button>                                        
                                            <Link to="/checkout" className="cartCheckBtn"><button variant="success" ><i className="fas fa-money-bill-wave fa-sm" aria-hidden="true"></i> Оформить заказ</button></Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ListGroup>
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>
        </main>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <Footer />
    </div>
    );
}

export default Cart;