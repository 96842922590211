import React, { useState, useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import swal from 'sweetalert';
import { clearCart } from "../../features/cartSlice";
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Checkout = () => {
    const [loading, setLoading] = useState(false);

    const basicSchema = yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        email: yup.string().email('Пожалуйста введите корректный e-mail').required('Это поле обязательно'),
        address: yup.string().required('Это поле обязательно'),
        phone: yup.string().required('Это поле обязательно'),
    });

    useEffect(() => {
        axios.get(`/api/getName`).then(res => {
            if (res.data.status === 200) {
                setProfileInputName(res.data);
            }
        }).catch();
    }, []);

    useEffect(() => {
        axios.get(`/api/profile`).then(res => {
            if (res.data.status === 200) {
                setCheckoutInput(res.data.profile);
            }
        }).catch();
    }, []);

    const [profileInputName, setProfileInputName] = useState({
        name: '',
        email: '',
    });

    useEffect(() =>{
        document.title = 'Оформление заказа - Интернет магазин Horeca Market';
    }, [])

    const cart = useSelector((state) => state.cart);
    const history = useHistory();

    const [checkoutInput, setCheckoutInput] = useState({
        phone: '',
        address: '',
        comment: '',
    });

    const handleInput = (e) =>{
        e.persist();
        setCheckoutInput ({...checkoutInput, [e.target.name]: e.target.value});
    }

    const handleInputName = (e) =>{
        e.persist();
        setProfileInputName ({...profileInputName, [e.target.name]: e.target.value});
    }
    const dispatch = useDispatch();

    const submitOrder  = async (e) => {
        e.preventDefault();

        
        const data = {            
            name: profileInputName.name,
            phone: checkoutInput.phone,
            email: profileInputName.email,
            address: checkoutInput.address,
            comment: checkoutInput.comment,
            cart: cart.cartItems,
            subtotal: cart.cartTotalAmount,
            total: cart.cartTotalAmount,
        }
        const isValid = await basicSchema.isValid(data);
        
        if(isValid){
            setLoading(true);
            axios.post(`/api/placeorder`, data).then(res => {
                if(res.data.status === 200){
                    setLoading(false);
                    swal("Спасибо за заказ! С Вами обязательно свяжется менеджер", res.data.message, 'success');
                    history.push('/');
                    dispatch(clearCart());
                } else if(res.data.status === 300){
                    setLoading(false);
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    swal("Добро пожаловать в TGR Market! Спасибо за заказ! С Вами обязательно свяжется менеджер", res.data.message, 'success');                    
                    history.push('/');
                    dispatch(clearCart());
                } else if(res.data.status === 422){
                    setLoading(false);
                    toast.error('Вы уже зарегистрированы, пожалуйста войдите на сайт!');
                }
            })
        }else{
            toast.error('Вы на заполнили обязательные поля!');
        }
    }

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Оформление заказа</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Оформление заказа</li>
                            </ul>
                        </div>
                        <div className="checkoutContent">
                            <form className="checkOutForm">
                                <div className="checkoutItem">
                                    <label>Ваше имя</label><span className="red">*</span><br />
                                    <input type="text" required onChange={handleInputName} name="name" value={profileInputName.name === null ? '' : profileInputName.name} className="inputAuth" />
                                </div>
                                <div className="checkoutItem">
                                    <label>Телефон</label><span className="red">*</span><br />
                                    <input type="phone" required name="phone" onChange={handleInput} value={checkoutInput.phone === null ? '' : checkoutInput.phone} className="inputAuth" />
                                </div>
                                <div className="checkoutItem">
                                    <label>e-mail</label><span className="red">*</span><br />
                                    <input type="email" required name="email" onChange={handleInputName} value={profileInputName.email === null ? '' : profileInputName.email} className="inputAuth" />
                                </div>
                                <div className="checkoutItem">
                                    <label>Адрес доставки</label><span className="red">*</span><br />
                                    <input type="text" required name="address" onChange={handleInput} value={checkoutInput.address === null ? '' : checkoutInput.address} className="inputAuth" />
                                </div>
                                <div className="checkoutItem">
                                    <label>Комментарий</label><br />
                                    <textarea name="comment" onChange={handleInput} value={checkoutInput.comment} className="inputAuth" />
                                </div>
                                <p className="red">Поля выделенные * - обязательны</p>
                                <div className="checkoutItemBtn">
                                    <button type='submit' onClick={submitOrder} disabled={loading}>
                                        {loading && <i className="fas fa-refresh fa-spin" aria-hidden="true"></i> }
                                        <i className="fas fa-money-bill-wave fa-sm" aria-hidden="true"></i>
                                        {loading && 'Загрузка' }
                                        {!loading && 'Заказать' }
                                    </button>
                                </div>
                            </form>
                            <div className="checkoutCart">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Продукт</th>
                                            <th>Цена</th>
                                            <th>Количество</th>
                                            {/* <th>Сумма</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cart.cartItems?.map((cartItem, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="checkName">{cartItem.name}</td>
                                                        {cartItem.selling_price
                                                        ?
                                                        <td>{cartItem.selling_price} / шт.</td>
                                                        :
                                                        <td>{cartItem.price} / шт.</td>
                                                        }
                                                        <td>{cartItem.cartQuantity}</td>                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td>Итого</td>
                                            <td colSpan={2}><NumberFormat displayType={'text'} thousandSeparator={' '} value={Math.floor(cart.cartTotalAmount)} /> ₸</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            <Footer />
        </div>
    )
}

export default Checkout
