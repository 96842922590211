import React, {  useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CallBack from "./CallBack/Modal";
import axios from "axios";


function Footer() {
  const [openModal, setOpenModal] = useState(false);

  const [category, setCategory] = useState([]); // API object of sections and categories

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`/api/getCategories`).then(res => {
            if (res.data.status === 200) {
                setCategory(res.data.category);
            }
        });
    }, []);

    //Category menu
    let categoryList = '';
    categoryList = category.map((item, idx) => {
        return (
            <li key={idx}>
              <Link to={`/catalog/${item.slug}`}>{item.name}</Link>
            </li>
        )
    });

  return (
    <footer>
      <div className="container">
        <div className="footerSubscribeWrap">
          <div className="footerSubscribe">
            <form>
              <input type="text" required="" placeholder="Подписаться на рассылку" />
              <button type="submit">OK</button>
            </form>
          </div>
          <p>Будьте в курсе наших <br />акций и новостей</p>
          <img src={`/uploads/design/subscribeAirplane.png`} alt="" />
        </div>
      </div>

      <div className="footerWrap">
        <div className="footer">
          <div className="container">
            <div className="footerBottomContentWrap">
              <div className="footerLogoWrap">
                <img src={`/uploads/design/footerLogo.jpg`} />
                <h4>Интернет магазин одноразовой <br />посуды в алматы</h4>
                <p>2022</p>
              </div>
              <div className="footerSocialWrap">
                <a href="#">
                  <i className="fab fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fab fa-vk" aria-hidden="true"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram-square" aria-hidden="true"></i>
                </a>
              </div>
              <div className="footerContentWrap">
                  <div className="footerContent">
                    <div className="footerCategory">
                      <h4>Категории</h4>
                      <ul>
                        {categoryList}
                      </ul>
                    </div>
                    <div className="footerCategory">
                      <h4>Навигация</h4>
                      <ul>                        
                        <li>
                          <Link to='/delivery'>Доставка и оплата</Link>
                        </li>
                        <li>
                          <Link to='/about'>О магазине</Link>
                        </li>
                        <li>
                          <Link to='/brands'>Бренды</Link>
                        </li>
                        <li>
                          <Link to='/articles'>Статьи и новости</Link>
                        </li>
                        <li>
                          <Link to='/cart'>Контакты</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="footerCategory">
                      <h4>Аккаунт</h4>
                      <ul>                        
                        <li>
                          <Link to='/cart'>Корзина</Link>
                        </li>
                        <li>
                          <Link to='/favourite'>Избранное</Link>
                        </li>
                        <li>
                          <Link to='/register'>Регистрация</Link>
                        </li>
                        <li>
                          <Link to='/login'>Вход</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="footerCategory">
                      <h4>Информация</h4>
                      <a href='tel:+77473333347' className="footerLink footerPhone">
                        <i className="fas fa-phone-volume" aria-hidden="true"></i>
                        <span>+7 747 </span>333 33 47
                      </a>
                      <a href='mailto:info@tgrmarket.kz' className="footerLink footerEmail">
                        <i className="fa-solid fa-envelope"></i>
                        info@tgrmarket.kz
                      </a>
                      <a href='#' className="footerLink footerAddress">
                        <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                        ул. Ратушного, 16 а.
                      </a>
                    </div>
                  </div>
                  <div className="footerCopyright">COPYRIGHT © ВСЕ ПРАВА ЗАЩИЩЕНЫ - <a href='/'>TGRMARKET.KZ</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && <CallBack closeModal={setOpenModal} />}
    </footer>
  );
}

export default Footer;