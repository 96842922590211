import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function HomeAboutCompany() {

    return (
        <Fragment>
            <div className="homeAboutCompany">
                <img src={`/uploads/content/homeAbout.jpg`} alt="" />
                <div className="homeAboutCompanyText">
                        Компания «TGR Company» рада приветствовать Вас в своем Интернет-магазине tgrmarket.kz и благодарит за оказанное доверие!
                        <p>Уже более 10 лет мы успешно снабжаем компании любого масштаба одноразовой посудой, пластиковыми контейнерами, пленкой, фальгой, алюминиевыми контейнерами, бумажными контейнерами, упаковкой, пакетами, гарбеджами, товарами для кондитерских и т.д.</p>
                        <p>На нашем сайте Вы найдете все необходимое для пикника, ресторана, бара, кафе, а также для дома и дачи.</p>
                        <p>Мы готовы предложить свой огромный опыт, широкий ассортимент, профессиональную команду, гибкую ценовую политику и индивидуальные условия работы, а также оптимальные логистические решения!</p>
                        <p>Вся наша продукция прошла сертификацию, произведена только из качественных и безвредных материалов. Обратившись в нашу компанию Вы получите отличный сервис и своевременную доставку продукции.</p>
                        <p>Надеемся на продуктивное и долгосрочное сотрудничество. С уважением, команда "TGR Company"!</p>
                    <Link to='/about'><img src={`/uploads/design/homeAboutBtn.jpg`} className='homeAboutBtn' alt="" /></Link>
                </div>
            </div>
        </Fragment>
    )
}

export default HomeAboutCompany;