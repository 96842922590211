import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';

const DashBoard = () => {
  const history = useHistory();
  const [order, setOrder] = useState([]);

  useEffect(() => {
      let isMounted = true;

      document.title = 'Личный кабинет пользователя';

      axios.get(`/api/orders`).then(res => {
          if(isMounted){
            if(res.data.status === 200){
              setOrder(res.data.order)
            }
          }  
      });
      return () => {
          isMounted = false;
      }
  }, []);

  var display_orders = '';

  display_orders = order.map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.status === 'ordered' ? 'Заказ оформлен' : '' || item.status === 'canceled' ? 'Отменен' : '' || item.status === 'delivered' ? 'Доставлен' : '' || item.status === 'paid' ? 'Оплачен' : '' || item.status === 'delivery' ? 'Доставка' : ''}</td>
                    <td><strong>{item.total} ₸</strong></td>
                    <td>{item.created_at.slice(0, 10)}</td>
                    <td>
                        <Link to={`/order/${item.id}`} className="btn btn-success btn-sm">Смотреть</Link>
                    </td>
                </tr>
            )
        });

  return (
    <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <div className='bashboardTopMenu'>
                            <h1>Личный кабинет</h1>
                            <div className='dashBoardTopBtns'>
                                <Link className='btn btn-primary btn-sm mr-2' to="/dashboard/profile">Профиль пользователя</Link>
                                <Link className='btn btn-primary btn-sm' to="/dashboard/password-update">Сменить пароль</Link>
                            </div>
                        </div>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Личный кабинет</li>
                            </ul>
                        </div>
                        <div className="checkoutContent">
                        <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Статус</th>
                                <th>Сумма заказа</th>
                                <th>Дата</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {display_orders}
                        </tbody>
                    </table>
                </div>
                        </div>
                    </div>
                  </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
  )
}

export default DashBoard;
