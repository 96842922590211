import React, { useEffect } from "react";
import {Helmet} from "react-helmet";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import HomeSlider from '../../components/frontend/HomePage/HomeSlider';
import HomeSlickProducts from '../../components/frontend/HomePage/HomeSlickProducts';
import HomeSlickCategories from '../../components/frontend/HomePage/HomeSlickCategories';
import HomeBanners from "../../components/frontend/HomePage/HomeBanners";
import HomeBests from "../../components/frontend/HomePage/HomeBests";
import HomeAboutCompany from "../../components/frontend/HomePage/HomeAboutCompany";
import HomeSeoText from "../../components/frontend/HomePage/HomeSeoText";


function Home(){
    return (
        <div className="root">
            <Helmet>
                <meta charset="utf-8" />
                <title>Одноразовая посуда купить Алматы оптом и в розницу. Пластиковая посуда. | TRG Market</title>
                <link rel="icon" href="/uploads/design/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="Одноразовая посуда, купить, продажа, Алматы, Казахстан, одноразовая посуда оптом, в розницу, Бытовая химия и средства для уборки, Пластиковые контейнера и столовые приборы, Плёнка, фольга , перчатки, чековая лента, " />
                <meta name="description" content="✅ Одноразовая посуда купить Алматы ☀ Одноразовая посуда оптом и в розницу ☀ Одноразовая посуда отличного качества ☀ Одноразовая посуда в Алматы ☀" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Одноразовая посуда купить Алматы оптом и в розницу - TGR Market" />
                <meta property="og:description" content="✅ Одноразовая посуда купить Алматы ☀ оптом и в розницу ☀ отличные цены всегда в наличии огромный ассортимент в Алматы ☀ Одноразовая посуда в Алматы ☀" />
                <meta property="og:keywords" content="" />
                <meta property="og:url" content="https://trgmarket.kz/" />
                <meta property="og:locale" content="ru_RU" />
                <meta property="og:image" content="Одноразовая посуда, купить, продажа, Алматы, Казахстан, одноразовая посуда оптом, в розницу" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
            </Helmet>
            <main>
                <Header />
                <div className="container">
                    <div className="homeSliderBanners">
                        <HomeSlider />
                        <HomeBanners />
                    </div>
                    <h2 className="homeTitle">
                        <img src={`/uploads/design/homeCatIcon.png`} alt="" />
                        Категории магазина
                    </h2>
                    <HomeSlickCategories />
                    <h2 className="homeTitle">
                        <img src={`/uploads/design/homeTrendIcon.png`} alt="" />
                        Товары в тренде
                    </h2>
                    <HomeSlickProducts />
                    <HomeBests />
                    <h2 className="homeTitle homeAboutTitle">
                        <img src={`/uploads/design/homeAboutIcon.png`} alt="" />
                        О МАГАЗИНЕ ОДНОРАЗОВОЙ ПОСУДЫ
                    </h2>
                    <HomeAboutCompany />
                    <HomeSeoText />
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;