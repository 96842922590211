import * as React from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './assets/css/style.css';
import './assets/css/style.scss';
import Login from './assets/frontend/auth/Login';
import PublicRoute from './PublicRoute';
import Register from './assets/frontend/auth/Register';
import AdminPrivateRoute from './AdminPrivateRoute';
import axios from "axios";
import { Redirect } from "react-router-dom";
import Page403 from "./assets/components/errors/Page403";
import Page404 from "./assets/components/errors/Page404";

axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:8000/';
axios.defaults.baseURL = 'https://tgrmarket.kz/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.interceptors.request.use(function(config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <AdminPrivateRoute path="/admin" name="Admin" />
          <PublicRoute path='/' name='Home' />

          <Route path="/403" component={Page403} />
          <Route path="/404" component={Page404} />

          <Route exact path="/login">
            {localStorage.getItem('auth_token') ? <Redirect to="/" /> : <Login /> }
          </Route>

          <Route exact path="/register">
            {localStorage.getItem('auth_token') ? <Redirect to="/" /> : <Register /> }
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
