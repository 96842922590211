import React, { useState, useEffect } from "react";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from "axios";


function HomeSlider() {
    const [slider, setSlider] = useState([]);

    useEffect(() => {
        axios.get(`/api/view-slides`).then(res => {
            if (res.data.status === 200) {
                setSlider(res.data.sliders);                
            }
        });
    }, []);

    let slideList = '';

    slideList = slider.map((item, idx) => {
        return (
            <Link to={item.link} key={idx}>
                <img src={`uploads/slider/${item.image}`} alt="" />
            </Link>
        );
    })

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="homeSlider">
            <Slider {...settings}>
                {slideList}
            </Slider>
        </div>
    );
}

export default HomeSlider;
