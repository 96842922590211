import React from 'react'

function HomeBests() {
  return (
    <div className='homeBestsWrap'>
        <div className='homeBestItem'>
            <img src={`/uploads/design/best1.jpg`} alt="" />
            <div className='homeBestItemText'>
                <h3>Всегда онлайн</h3>
                <p>На связи 24/7</p>
            </div>
        </div>
        <div className='homeBestItem'>
            <img src={`/uploads/design/best2.jpg`} alt="" />
            <div className='homeBestItemText'>
                <h3>клиентский сервис</h3>
                <p>Вежливый персонал</p>
            </div>
        </div>
        <div className='homeBestItem'>
            <img src={`/uploads/design/best3.jpg`} alt="" />
            <div className='homeBestItemText'>
                <h3>ВОЗВРАТ ТОВАРА</h3>
                <p>В течении 14 дней</p>
            </div>
        </div>
        <div className='homeBestItem'>
            <img src={`/uploads/design/best4.jpg`} alt="" />
            <div className='homeBestItemText'>
                <h3>ГАРАНТИЯ ПРОДУКЦИИ</h3>
                <p>Сертификаты качества</p>
            </div>
        </div>
    </div>
  )
}

export default HomeBests
