import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
    favItems: localStorage.getItem('favItems') 
    ? JSON.parse(localStorage.getItem('favItems')) 
    : [],
}

const favSlice = createSlice ({
    name: 'fav',
    initialState,
    reducers: {
        addToFav(state, action){
            const itemIndex = state.favItems.findIndex((item) => item.id === action.payload.id); // Find index in state and match with incoming item.id
            if(itemIndex >= 0){
                const nextCartItems = state.favItems.filter(
                    (favItem) => favItem.id !== action.payload.id
                );
                state.favItems  = nextCartItems;
                localStorage.setItem("favItems", JSON.stringify(state.favItems));
    
                toast.error(`${action.payload.name} удален из избранного`, {
                    position:"bottom-left"
                });
            } else {
                const tempFavProduct = {...action.payload, cartQuantity: action.payload.qty};
                state.favItems.push(tempFavProduct);
                toast.success(`${action.payload.name} добавлен(о) в избранное`, {
                    position:'top-center',
                });

                localStorage.setItem('favItems', JSON.stringify(state.favItems));
            }
            
        },
        removeFromFav(state, action) {
            const nextCartItems = state.favItems.filter(
                (favItem) => favItem.id !== action.payload.id
            )
            state.favItems  = nextCartItems;
            localStorage.setItem("favItems", JSON.stringify(state.favItems))

            toast.error(`${action.payload.name} удален из избранного`, {
                position:"bottom-left"
            });
        }
    },
});

export const { addToFav, removeFromFav, moveFavToCart } = favSlice.actions;

export default favSlice.reducer;