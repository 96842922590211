import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function ViewOrders() {

    const [loading, setLoading] = useState(true);
    const [orderList, setOrderList] = useState([]);

    useEffect( () => {
        axios.get(`/api/view-orders`).then(res => {
            if(res.data.status === 200){
                setOrderList(res.data.orders);
                setLoading(false);
            }
        }).catch();
    }, []);

    let vieworder_HTMLTABLE = '';

    if(loading){
        return <h4>Загрузка заказов...</h4>
    }else{
        vieworder_HTMLTABLE = orderList.map((item) => {
            return(
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.address}</td>
                    <td>{item.comment}</td>
                    <td>{item.total}</td>
                    <td>{item.created_at.slice(0, 10)}</td>
                    <td>
                        {item.status === 'ordered' ? 'Заказ оформлен': ''}                        
                        {item.status === 'delivery' ? 'Доставка': ''}
                        {item.status === 'paid' ? 'Оплачен': ''}
                        {item.status === 'delivered' ? 'Доставлен': ''}
                        {item.status === 'canceled' ? 'Отменен': ''}
                    </td>
                    <td className="adminOrderTableSelect">
                        <Link to={`/admin/edit-order/${item.id}`} className="btn btn-success btn-sm">Смотреть заказ</Link>                        
                    </td>
                </tr>
            )
        });
    }


    return (
        <div className="container px-4 adminOrdersTable">
            <div className="card mt-4">
                <div className="card-header">
                    <div className=" row">
                        <div className="col-sm"><h4>Все заказы</h4></div>                                     
                    </div>
                </div>
                <div className="card-body">
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Название</th>
                                <th>Телефон</th>
                                <th>E-mail</th>
                                <th>Адрес</th>
                                <th>Комментарий</th>
                                <th>Сумма</th>
                                <th>Дата заказа</th>
                                <th>Статус</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vieworder_HTMLTABLE}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ViewOrders
