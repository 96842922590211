import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from "../../../features/cartSlice";
import { addToFav } from "../../../features/favSlice";
import { useDispatch, useSelector } from 'react-redux';
import CategoryImage from "../../../frontend/pages/CategoryImage";
import QuickModal from "../Modal/QuickModal";

function SaleProduct() {
    const [openQuick, setOpenQuick] = useState(false);
    const [quickProduct, setQuickProduct] = useState('');
    const [quickCategory, setQuickCategory] = useState('');
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit};                
        dispatch(addToCart(newItem))
    }
    const favourite = useSelector((state) => state.fav.favItems);

    const handleAddToFav = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit}; 
        dispatch(addToFav(newItem));        
    }

    const [saleProd, setSaleProd] = useState([]);

    useEffect(() => {
        axios.get(`/api/getRemark/sale`).then(res => {
            if (res.data.status === 200) {
                setSaleProd(res.data.products);                
            }
        });
    }, []);

    const handleIncrement = (index, qty) => {   
        const newItems = [...saleProd];
        newItems[index].qty = newItems[index].qty + qty;
        newItems[index].quantity = qty;
        setSaleProd(newItems);
    }

    const handleDecrement = (index, qty) => {         
        const newItems = [...saleProd];
        if(newItems[index].qty > qty){
            newItems[index].qty = newItems[index].qty - qty;
            newItems[index].quantity = qty;
            setSaleProd(newItems);            
        }
    }

    const quickHandler = (product_slug, category_slug) => {
        setOpenQuick(!openQuick);
        setQuickProduct(product_slug);
        setQuickCategory(category_slug);
    }

    const handleSetQty = (type, idx) => {
        const newItems = [...saleProd];
        if(newItems[idx].quantity === type){

        }else{                  
            newItems[idx].qty = type;
            newItems[idx].quantity = type;
            setSaleProd(newItems);
        }        
    }

    let productList = '';
    productList = saleProd.map((item, idx) => {
        const isItemInFavourite = favourite.some(items => items.id === item.id);
        let defaultItem;
        if(item.quantity != undefined){
            defaultItem = item.quantity; 
        }else{
            defaultItem = item.unit_up === null ? item.unit_kor : item.unit_up;
        }
        return (
            <div className="homeProductItem" key={idx}>
                <div className="productFront">
                    <div className="quickViewProduct" onClick={() => quickHandler(item.slug, item.searchCategory.slug)}><i class="fa-sharp fa-solid fa-eye"></i>Быстрый просмотр</div>                    
                    <div className="homeProductItemImg">
                        <Link to={`/catalog/${item.searchCategory.slug}/${item.slug}`} className="productImageWrap">
                            <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                        </Link>
                        {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                    </div>
                    <div className="homeProductItemInfo">
                        {item.selling_price
                        ?
                        <div>
                            <div className="homeProductItemPrice red"><strong>{parseInt(item.selling_price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.selling_price}₸ / шт.</span></div>
                            <div className="productDetailsSPriceSale"><strong>{item.price} <span>₸</span></strong> / шт.</div>
                        </div>
                        :
                        <div className="productDetailsSPrice"><strong>{parseInt(item.price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.price}₸ / шт.</span> </div>
                        }
                    </div>
                    <Link to={`/catalog/${item.searchCategory.slug}/${item.slug}`}><h2 className="productH2">{item.name}</h2></Link>
                    <div className='cartUnitType'>
                        {item.unit_up === null ? '' :<button className={`${item.unit_up === defaultItem ? 'active' : ''}`} onClick={() => handleSetQty(item.unit_up, idx)}>уп. ({item.unit_up} шт.)</button>}
                        {item.unit_kor === null ? '' : <button className={`${item.unit_kor === defaultItem ? 'active' : ''}`} onClick={() =>  handleSetQty(item.unit_kor, idx)}>кор. ({item.unit_kor} шт.)</button>}
                    </div>
                    <div className="productQty">
                        <button className="cartMinus" onClick={() =>  handleDecrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>-</button>
                        <div>
                            {item.qty === 0 ? item.qty = defaultItem : item.qty}
                        </div>
                    
                        <button className="cartPlus" onClick={() =>  handleIncrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>+</button>
                    </div>

                    <div className="catProductBtn">
                        <button
                            className="catProdAdd cartBtnProduct"
                            onClick={() => handleAddToCart(item, item.qty, item.quantity === undefined ? defaultItem : item.quantity)}
                        ><i className="fa-solid fa-basket-shopping"></i> Купить</button>
                        <button
                            onClick={() => handleAddToFav(item, item.qty, item.quantity === undefined ? defaultItem : item.quantity)}
                            className="catProdAdd favBtnProduct"
                        ><i className="fa-sharp fa-solid fa-star"></i></button>
                    </div>
                    <div className="productBackground"></div>
                </div> 
            </div>
        )
    });

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: false,
                    speed: 300,
                    autoplay: true,
                    centerPadding: '10px',
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings}>
                {productList}
            </Slider>
            {openQuick && <QuickModal closeModal={setOpenQuick} product_slug={quickProduct} category_slug={quickCategory} />}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default SaleProduct;