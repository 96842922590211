import React from 'react';

function Page404 () {
    return (
        <div className='container'> 
            <h1>Страница 404 | Страница не найдена</h1>
        </div>
    )
}

export default Page404;