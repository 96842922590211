import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';

function Articles() {
    useEffect(() =>{
        document.title = 'Статьи и новости - Интернет магазин TGR Market';
    }, []);
  return (
    <div className="root">            
        <main>
            <Header />
            <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Статьи и новости</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li>Статьи и новости</li>
                        </ul>
                    </div>
                    <div class="commonPagesContent">
                        
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>
        </main>
        <Footer />
    </div>
  )
}

export default Articles
