import React, { useState, useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from "@mui/material";
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





function Register() {
    const passwordRules = /^(?=.*\d)(?=.*[A-Z]).{5,}$/;
    
    const basicSchema = yup.object().shape({
        email: yup.string().email('Пожалуйста введите корректный e-mail').required('Это поле обязательно'),
        name: yup.string().required('Это поле обязательно'),
        password: yup.string().min(5).matches(passwordRules, {message: "Пожалуйста введите пароль посложнее"}).required('Это поле обязательно'),
        confirmPassword: yup.string().oneOf([yup.ref('password'),  'Пароли не совпадают']).required('Это поле обязательно'),
    });
    
    useEffect(() =>{
        document.title = 'Регистрация пользователя - Интернет магазин TGR Market';
    }, [])

    const history = useHistory();

    

    const [registerInput, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value });
    }//end method

    const registerSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name:registerInput.name,
            email:registerInput.email,
            password:registerInput.password,
            confirmPassword:registerInput.confirmPassword,
        }

        const isValid = await basicSchema.isValid(data)
        //console.log(isValid)
        if(isValid){
            axios.get('/sanctum/csrf-cookie').then(response => { 
                axios.post(`/api/register`, data).then(res => {
                    if(res.data.status === 200){
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.username);
                        swal("Добро пожаловать в TGR Market!", res.data.message, 'success');
                        history.push('/');
                    }else if (res.data.status === 422){
                        swal("Такой пользователь уже есть!", res.data.message, 'error');
                    }
                }).catch();
            });
        }else{
            toast.error('Пароли не совпадают или слишком простые');
        }
    }//end method

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Регистрация</h1>
                    <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Регистрация</li>                               
                            </ul>
                        </div>
                    <div className="formMainAuth">
                        <form onSubmit={registerSubmit}>
                            
                            <div className="inputField">
                                <i className="fa-solid fa-user"></i>
                                <input 
                                    type="text" 
                                    required 
                                    onChange={handleInput} 
                                    value={registerInput.name} 
                                    className='modalInput'
                                    name="name" 
                                    placeholder="Ваше имя"
                                />
                            </div><br/>
                            
                            <div className="inputField">                            
                                <i className="fa-solid fa-envelope"></i>
                                <input 
                                    type="email" 
                                    required 
                                    onChange={handleInput} 
                                    value={registerInput.email} 
                                    name="email" 
                                    placeholder="Ваш email"
                                    className="modalInput"
                                    
                                />                                
                            </div><br/>
                            <p>Минимально 5 символов, 1 заглавная буква и 1 цифра!</p>
                            
                            <div className="inputField">
                            <i className="fa-solid fa-key"></i>
                                <input 
                                    type="text" 
                                    required 
                                    onChange={handleInput} 
                                    value={registerInput.password} 
                                    name="password" 
                                    className="modalInput"
                                    placeholder="Введите пароль"
                                />
                            </div><br/>
                            
                            <div className="inputField">
                            <i className="fa-solid fa-key"></i>
                                <input 
                                    type="text" 
                                    required 
                                    onChange={handleInput} 
                                    value={registerInput.confirmPassword} 
                                    name="confirmPassword"
                                    className="modalInput"
                                    placeholder="Повторите пароль"
                                />
                            </div>
                            <div className="formMainAuthField">
                                <button type="submit" className="modalInputBtn">Регистрация</button>
                            </div>
                        </form>
                        <img src="/uploads/design/forgot.jpg" alt="" />
                    </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            <Footer />
        </div>
    )
}

export default Register;