import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";


function Categories(){
    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        axios.get(`/api/getCategories`).then(res => {
            if(res.data.status === 200){
                setCategory(res.data.category);
                setLoading(false);
            }   
        });
    }, []);

    let viewcategory_HTMLTABLE = '';

    if(loading){
        return <h4>loading...</h4>
    }else{
        viewcategory_HTMLTABLE = 
        category.map((item, idx) => {
            return(
                <div key={idx}>{item.name}</div>
            )
        });
    }

    return (
        <div className="root">
            <main>
                <Header />   
                <div className="container">             
                    <h1>Категории</h1>                
                    <div className="categoriesContentWrap">                        
                        {viewcategory_HTMLTABLE}
                    </div>
                </div>
                <div className="hFooter"></div>                
            </main>
            <Footer />
        </div>
    );
}

export default Categories;