import React, { useEffect, useState } from 'react'
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ListGroup, Row, Col, Image } from "react-bootstrap";


const OneOrder = (props) => { // All comes from parent URL
    const [order, setOrder] = useState([]);

    useEffect(() => {

        const orderId = props.match.params.id;
        
        axios.get(`/api/order/${orderId}`).then(res=>{
            if(res.data.status === 200){
                setOrder(res.data.orderItems);
            } else if(res.data.status === 404){

            }
        });

    }, [props.match.params.id]);

    var orderId = props.match.params.id;
    var $order = '';

    $order = order.map((item, idx) => {
        return(
            <ListGroup.Item key={idx} >
                <Row>
                    <Col md={2}>
                        <Image src={`/uploads/products/${item.image}`} alt={item.name} fluid rounded />
                    </Col>
                    <Col md={6}>
                        <h3>{item.name}</h3>
                    </Col>
                    <Col md={1}>
                        <h3>{item.qty}</h3>
                    </Col>
                    <Col md={1}>
                        <div className="cartProdPrice">{item.price} ₸</div>
                    </Col>
                    <Col md={2}>
                        <div className="cartProdTotalPrice">{item.price * item.qty} ₸</div>
                    </Col>
                </Row>
            </ListGroup.Item>
        )
    });


  return (
    <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Содержимое заказа № {orderId}</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li><Link to="/dashboard">Кабинет</Link></li>
                                /
                                <li>Заказ № {orderId}</li>
                            </ul>
                        </div>
                        <div className="orderContent">
                            {$order}
                        </div>
                        <Link className='btn btn-success btn-sm mt-4' to="/dashboard">Назад в кабинет</Link>
                    </div>
                  </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
  )
}

export default OneOrder
