import React from 'react';
import { Link } from 'react-router-dom';

function HomeBanners() {
  return (
    <div className='homeBanners'>
        <Link to="/catalog/plastikovye-konteinera-i-stolovye-pribory">
            <img src={`/uploads/banner/banner1.jpg`} alt="" />
        </Link>
        <Link to="/catalog/plastikovye-konteinera-i-stolovye-pribory">
            <img src={`/uploads/banner/banner2.jpg`} alt="" />
        </Link>
    </div>
  )
}

export default HomeBanners
