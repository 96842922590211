import Dashboard from "../components/admin/Dashboard";
import Setting from "../components/admin/Setting";
import AdminPassword from "../components/admin/AdminPassword";
//Category components
import Category from "../components/admin/category/Category";
import ViewCategory from "../components/admin/category/ViewCategory";
import EditCategory from "../components/admin/category/EditCategory";
//Product components
import Product from "../components/admin/product/AddProduct";
import ViewProduct from "../components/admin/product/ViewProduct";
import EditProduct from "../components/admin/product/EditProduct";
//Brand components
import AddBrand from "../components/admin/brand/AddBrand";
import ViewBrand from "../components/admin/brand/ViewBrand";
import EditBrand from "../components/admin/brand/EditBrand";
//News components
import AddNews from "../components/admin/news/AddNews";
import ViewNews from "../components/admin/news/ViewNews";
//Slider components
import ViewSliders from "../components/admin/slider/ViewSliders";
import AddSlide from "../components/admin/slider/AddSlide";
import EditSlide from "../components/admin/slider/EditSlide";
//Pages components
import About from "../components/admin/pages/About";
import DeliveryPayment from "../components/admin/pages/DeliveryPayment";
//Order components
import ViewOrders from "../components/admin/order/ViewOrders";
import OneOrder from "../components/admin/order/OneOrder";



const routes = [

    { path: '/admin', exact: true, name:'Admin'},
    { path: '/admin/dashboard', exact: true, name:'Dashboard', component: Dashboard},
    { path: '/admin/password', exact: true, name:'AdminPassword', component: AdminPassword},

    //Category routes
    { path: '/admin/add-category', exact: true, name:'Category', component: Category},
    { path: '/admin/view-category', exact: true, name:'ViewCategory', component: ViewCategory},
    { path: '/admin/edit-category/:id', exact: true, name:'EditCategory', component: EditCategory},

    //Product routes
    { path: '/admin/add-product', exact: true, name:'Product', component: Product},
    { path: '/admin/view-product', exact: true, name:'ViewProduct', component: ViewProduct},
    { path: '/admin/edit-product/:id', exact: true, name:'EditProduct', component: EditProduct},

    //Brand routes
    { path: '/admin/add-brand', exact: true, name:'AddBrand', component: AddBrand},
    { path: '/admin/view-brand', exact: true, name:'ViewBrand', component: ViewBrand},
    { path: '/admin/edit-brand/:id', exact: true, name:'EditBrand', component: EditBrand},

    //News routes
    { path: '/admin/add-news', exact: true, name:'AddNews', component: AddNews},
    { path: '/admin/view-news', exact: true, name:'ViewNews', component: ViewNews},

    //Orders routes
    { path: '/admin/orders', exact: true, name:'ViewOrders', component: ViewOrders},
    { path: '/admin/edit-slide/:id', exact: true, name:'EditSlide', component: EditSlide},

    //Slider routes
    { path: '/admin/add-slide', exact: true, name:'AddSlide', component: AddSlide},
    { path: '/admin/view-slider', exact: true, name:'ViewSliders', component: ViewSliders},
    { path: '/admin/edit-order/:id', exact: true, name:'OneOrder', component: OneOrder},

    //Setting routes
    { path: '/admin/setting', exact: true, name:'Setting', component: Setting},

    //Pages routes
    { path: '/admin/about', exact: true, name:'About', component: About},
    { path: '/admin/delivery_payment', exact: true, name:'DeliveryPayment', component: DeliveryPayment},

]

export default routes;