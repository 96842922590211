import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function AddNews() {
    const [newsInput, setNews] = useState({
      title: '',
      content: '',
      description: '',
      //status: '',
      meta_title: '',
      meta_keywords: '',
      meta_description: '',      
    }); //Get all input data
    const [picture, setPricture] = useState([]);

    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setNews({ ...newsInput, [e.target.name]: e.target.value });
    }

    const handleImage = (e) => {
        setPricture({ image: e.target.files[0] });
    }


    

    

    const submitNews = (e) => {
        e.preventDefault();

        const formData = new FormData();//Only if u use image data
        formData.append('image', picture.image);
        formData.append('title', newsInput.title);
        formData.append('content', newsInput.content);
        formData.append('description', newsInput.description);
        //formData.append('status', newsInput.status);
        formData.append('meta_title', newsInput.meta_title);
        formData.append('meta_keywords', newsInput.meta_keywords);
        formData.append('meta_description', newsInput.meta_description);

        
        axios.post(`/api/store-news`, formData).then(res => {
            if(res.data.status === 200){
                swal("Новость успешно создана!", res.data.message, 'success');
                setNews({...newsInput,
                  title: '',
                  content: '',
                  description: '',
                  //status: '',
                  meta_title: '',
                  meta_keywords: '',
                  meta_description: '',      
                });
            } else if(res.data.status === 400){
                toast.error('Не все поля заполнены');
                setNews({ ...newsInput, error_list:res.data.errors }); //Записываем ошибки в массив
            }
        });
    }


  return (
    <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Добавить новость</h1>
            <form onSubmit={submitNews} id="NEWS_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="form-group mb-3">
                            <label className="mb-2"><b>Заголовок новости</b></label>
                            <textarea type="text" name="title" onChange={handleInput} value={newsInput.title} className="form-control" ></textarea>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Текст новости</b></label>
                            <textarea type="text" name="content" onChange={handleInput} value={newsInput.content} className="form-control">
                            </textarea>
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Картинка<span className="red">*</span></b></label>
                            <input
                                type="file"
                                enctype="multipart/form-data"
                                required
                                name="image"
                                onChange={handleImage}
                                value={newsInput.image}
                                className="form-control" 
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание новости</b></label>
                            <textarea type="text" name="description" onChange={handleInput} value={newsInput.description} className="form-control" ></textarea>
                        </div>
                        
                        {/* <div className="form-group mb-3">
                            <input className="form-check-input" type="checkbox" onChange={handleInput} value={newsInput.status} name="status" id="flexCheckChecked" />
                            <label className="form-check-label ml-2" htmlFor="flexCheckChecked">
                                <b>Статус</b>
                            </label>
                            <p>нет галочки = включена / есть галочка = отключена</p>
                        </div> */}
                        
                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={newsInput.meta_title} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={newsInput.meta_keywords} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={newsInput.meta_description} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить новость</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
  )
}

export default AddNews
