import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";

function Hits() {
    useEffect(() =>{
        document.title = 'Хиты продаж - Интернет магазин TGR Market';
    }, []);
    return (
    <div className="root">
        <main>
            <Header />
            <div className="container">
                <div className="commonPagesContentWrap">
                    <h1>Хиты продаж</h1>
                    <div className="bread">
                        <ul>
                            <li><Link to="/">Главная</Link></li>
                            /
                            <li>Хиты продаж</li>                               
                        </ul>
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>
        </main>
        <Footer />
    </div>
    )
}

export default Hits
