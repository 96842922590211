import React, { useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "@mui/material";



function Delivery(){
    useEffect(() =>{
        document.title = 'Доставка и оплата - Интернет магазин TGR Market';
    }, [])

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Доставка и оплата</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Доставка и оплата</li>                               
                            </ul>
                        </div>
                        <div className='commonPagesContent deliveryPagesContent'>
                            <h3>Способы доставки:</h3>
                            <p>При заказе в интернет-магазине tgrmarket.kz стоимость доставки зависит от города и суммы заказа.</p>
                            <p>Доставка действует только свыше 15 000 тг. бесплатно. Ниже этой суммы доставка договорная.</p>
                            <p>Доставка по РК Казахстан - осуществляется по тарифам транспортных компаний г. Алматы.</p><br />
                            <h3>Способы оплаты:</h3>
                            <p>Мы принимаем:Kaspi QR, Kaspi Pay, Kaspi перевод, наличными курьеру при доставки, для юридических лиц оплата производится на основании счета безналичным переводом, со всеми закрывающими документами.</p>
                            <p>Все платежи принимаются исключительно в национальной валюте.</p>
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Delivery;