import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HomeSlickCategories(){
    const [category, setCategory] = useState([]); // API object of sections and categories

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`/api/getCategories`).then(res => {
            if (res.data.status === 200) {
                setCategory(res.data.category);
            }
        });
    }, []);

    //Category menu
    let categoryList = '';
    categoryList = category.map((item, idx) => {
        return (
            <Link to={`/catalog/${item.slug}`} className="homeCategoryItem" key={idx}>
                <img className="homeCategoryImg" src={`/uploads/categories/${item.image}`} alt="" />
                <p>{item.name}</p>
            </Link>
        )
    });

    var settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed:300,
        slidesToShow: 6,
        slidesToScroll: 1,
        pauseOnFocus: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    speed: 300,
                    autoplay: true,
                    centerPadding: '10px',
                }
            }
        ]
    };
    return (
        <div className="homeSlickCategoriesWrap">
            <div className="homeSlickCategories">
                <Slider {...settings}>
                    {categoryList}
                </Slider>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    );
}

export default HomeSlickCategories;