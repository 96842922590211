import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';
import axios from "axios";


function Brands(){       

    const [brands, setBrands] = useState([]);

    useEffect(() => {
        document.title = 'Бренды - Интернет магазин TGR Market';

        axios.get(`/api/getBrands`).then(res => {
            if(res.data.status === 200){
                setBrands(res.data.allBrands);
            }   
        });
    }, []);

    let viewBrand = '';

    viewBrand = brands.map((item, idx) => {
            return(
                <Link key={idx} to={`brand/${item.slug}`} className="brandItem">
                    <img src={`/uploads/brands/${item.image}`} />
                    <h3>{item.name}</h3>
                </Link>
            )
        });

    return (
        <div className="root">            
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Бренды</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Бренды</li>
                            </ul>
                        </div>
                        <div className='brandsContentWrap'>
                            {viewBrand}
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Brands;