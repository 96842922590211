import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from 'react-router-dom';



function Contacts() {

    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Контакты</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Контакты</li>
                            </ul>
                        </div>
                        <div className='commonPagesContent contactsContentWrapper' >
                            <div className="contactsLeft">
                                <div className="contactsContent">
                                    Контакты
                                </div>
                            </div>
                            <div className="contactsRight">
                                Карта
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
        </div>
    );
}

export default Contacts;