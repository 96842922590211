import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToFav } from "../../features/favSlice";
import { addToCart } from "../../features/cartSlice";
import { useDispatch, useSelector } from 'react-redux';
import CategoryImage from "./CategoryImage";
import Skeleton from "./skeleton";
import QuickModal from "../../components/frontend/Modal/QuickModal";


function OneCategory(props){
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit};                
        dispatch(addToCart(newItem))
    }
    const favourite = useSelector((state) => state.fav.favItems);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }
    const [openQuick, setOpenQuick] = useState(false);
    const [quickProduct, setQuickProduct] = useState('');
    const [quickCategory, setQuickCategory] = useState('');
    const history = useHistory();
    const [product, setProduct] = useState([]); //Products coming once from category
    const [productCalc, setProductCalc] = useState([]); //Products calc in one category
    const [checkedValues, setCheckedValues] = useState([]); //Values of brands
    //const [lastPage, setLastPage] = useState(0);
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [filters, setFilters] = useState({ //Filters result in category page
        s: '',
        sort: '',
        newCheckedValues: [],
        //page: 1,
    });

    //const perPage = 12; // Defaul products per page
    

    useEffect(() => {
        //window.scrollTo(0, 0);
        let isMounted = true;
        const product_slug = props.match.params.slug;
        (
            async () => { 
                await axios.get(`/api/fetchProducts/${product_slug}`).then(res => {
                    if(isMounted){
                        if(res.data.status === 200){
                            setProduct(res.data.product_data.product);
                            setProductCalc(res.data.product_data.product.length)
                            setFilteredProduct(res.data.product_data.product.slice(0, filters.page ));//* perPage
                            setCategory(res.data.product_data.category);
                            document.title = res.data.product_data.category.name;
                            //setLastPage(Math.ceil(res.data.product_data.length / perPage));
                            setLoading(false);
                        } else if( res.data.status === 400 ){
                            toast.error('Внимание нет товаров');
                        } else if( res.data.status === 404 ){
                            history.push('/categories');
                            toast.error('Нет такой категории');
                        }
                    }
            });
        })()
        return () => {
            isMounted = false;
        }
    }, [props.match.params.slug, history ]);

    useEffect(() => {
        let products = product.filter(p => p.name.toLowerCase().indexOf(filters.s.toLowerCase()) >= 0); //Search filtering
        
        if(filters.sort === 'asc' || filters.sort === 'desc'){ //Sorting filtering
            products.sort((a, b) => {                
                const diff = a.price - b.price;
                if(diff === 0) return 0;
                const sign = Math.abs(diff) / diff;                
                return filters.sort === 'asc' ? sign : -sign;
            });
        }
        //Brands filtering
        
         if(filters.newCheckedValues.length !== 0){
            const filterData = products.filter(item => checkedValues.includes(item.brand));
            setFilteredProduct(filterData);
            //console.log(filterData);
            

            //setLastPage(Math.ceil(filterData.length / perPage));

            setFilteredProduct(filterData.slice(0, filters.page ));//* perPage
         } else{
            //setLastPage(Math.ceil(products.length / perPage));

            setFilteredProduct(products.slice(0, filters.page ));//* perPage
         }
        
    }, [filters]); //Use Effect only for filters

    const searchCategory = (s) => {
        setFilters({
            ...filters,
            s
        });
    };

    const sort = (sort) => {
        setFilters({
            ...filters,
            sort
        }); 
    }

    // const loadItems = () => {
    //     setFilters({
    //         ...filters,
    //         page: filters.page + 1,
    //     }); 
    // }

    const handleBrandChecked = e => {
        const brand = displayBrands[e.target.dataset.id];
        let newCheckedValues = checkedValues.filter((item) => item !== brand);
        if (e.target.checked) newCheckedValues.push(brand);
        setCheckedValues(newCheckedValues);

        setFilters({
            ...filters,
            newCheckedValues
        }); 
    }

    const handleIncrement = (index, qty) => {   
        const newItems = [...filteredProduct];
        newItems[index].qty = newItems[index].qty + qty;
        newItems[index].quantity = qty;
        setFilteredProduct(newItems);
    }

    const handleDecrement = (index, qty) => {         
        const newItems = [...filteredProduct];
        if(newItems[index].qty > qty){
            newItems[index].qty = newItems[index].qty - qty;
            newItems[index].quantity = qty;
            setFilteredProduct(newItems);            
        }
    }
    const quickHandler = (product_slug, category_slug) => {
        setOpenQuick(!openQuick);
        setQuickProduct(product_slug);
        setQuickCategory(category_slug);
    }

    const handleSetQty = (type, idx) => {
        const newItems = [...filteredProduct];
        if(newItems[idx].quantity === type){

        }else{                  
            newItems[idx].qty = type;
            newItems[idx].quantity = type;
            setFilteredProduct(newItems);
        }        
    }

    // let button;
    // if(filters.page !== lastPage && filteredProduct.length >= 12){
    //     button = (
    //         <div className="categoryBtnMore">
    //             <button onClick={loadItems}>Загрузить еще товары</button>
    //         </div>
    //     )    
    // }
    var showProductList = '';
    if(loading){
        showProductList = <Skeleton type="feed" counter='8' />;
    }else{
        showProductList = filteredProduct.map((item, idx) => { //Maping the result of filtering 
            let defaultItem;
            if(item.quantity != undefined){
                defaultItem = item.quantity; 
            }else{
                defaultItem = item.unit_up === null ? item.unit_kor : item.unit_up;
            }
            
            return(
                <div className="homeProductItem" key={idx}>
                    <div className="productFront">
                        <div className="quickViewProduct" onClick={() => quickHandler(item.slug, item.searchCategory.slug)}><i class="fa-sharp fa-solid fa-eye"></i>Быстрый просмотр</div>   
                        <div className="homeProductItemImg">
                            <Link to={`${category.slug}/${item.slug}`}>
                                <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                            </Link>
                            {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                        <div className="homeProductItemInfo">
                            {item.selling_price
                            ?
                            <div>
                                <div className="homeProductItemPrice red"><strong>{parseInt(item.selling_price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.selling_price}₸ / шт.</span></div>
                                <div className="productDetailsSPriceSale"><strong>{item.price} <span>₸</span></strong> / шт.</div>
                            </div>
                            :
                            <div className="productDetailsSPrice"><strong>{parseInt(item.price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.price}₸ / шт.</span> </div>
                            }
                        </div>
                        <Link to={`${category.slug}/${item.slug}`}><h2 className="productH2">{item.name}</h2></Link>
                        <div className='cartUnitType'>
                            {item.unit_up === null ? '' :<button className={`${item.unit_up === defaultItem ? 'active' : ''}`} onClick={() => handleSetQty(item.unit_up, idx)}>уп. ({item.unit_up} шт.)</button>}
                            {item.unit_kor === null ? '' : <button className={`${item.unit_kor === defaultItem ? 'active' : ''}`} onClick={() =>  handleSetQty(item.unit_kor, idx)}>кор. ({item.unit_kor} шт.)</button>}
                        </div>
                        <div className="productQty">
                            <button className="cartMinus" onClick={() =>  handleDecrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>-</button>
                            <div>
                                {item.qty === 0 ? item.qty = defaultItem : item.qty}
                            </div>
                        
                            <button className="cartPlus" onClick={() =>  handleIncrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>+</button>
                        </div>

                        <div className="catProductBtn">
                            <button
                                className="catProdAdd cartBtnProduct"
                                onClick={() => handleAddToCart(item, item.qty, item.quantity === undefined ? defaultItem : item.quantity)}
                            ><i className="fa-solid fa-basket-shopping"></i> Купить</button>
                            <button
                                onClick={() => handleAddToFav(item)}
                                className="catProdAdd favBtnProduct"
                            ><i className="fa-sharp fa-solid fa-star"></i></button>
                        </div>
                        <div className="productBackground"></div>
                    </div>
                </div>
            )
        });
    }
    let displayBrands = [];
    let displaySortBrands = [];
    let displayBrandsOutput = [];
    if(loading){
        displayBrandsOutput = <Skeleton type="brand" />;
    }else{
        
        displayBrands = [...new Set(product.map((item => item.brand)))];
        
        displaySortBrands = displayBrands.sort(function (a, b) {
            return a.localeCompare(b); //using String.prototype.localCompare()
        });
        
        
        displayBrandsOutput = displaySortBrands.map((item,idx) =>{            
            return (
                <div key={idx}>                    
                    <input 
                        type="checkbox" 
                        data-id={idx} 
                        id={idx}
                        onClick={handleBrandChecked}
                    />
                    <label htmlFor={idx} className="brandLabel">
                        {item}
                    </label><br />
                </div>
            )
        });        
    }
    
    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">   
                    <div className="oneCategoryWrap">
                        <h1><p>{category.name}</p><span>Товаров: {productCalc}</span></h1>
                        <div className="categoryNavigatinoWrap">
                            <div className="bread">
                                <ul>
                                    <li><Link to="/">Главная</Link></li>
                                    /
                                    <li>{category.name}</li>
                                </ul>
                            </div>           
                            <div className="categorySearch">
                                <input 
                                    type="text" id="catSeach" 
                                    onChange={e => searchCategory(e.target.value)} 
                                />
                                <label htmlFor="catSeach" className="catSeachLabel">
                                    <span className="catSeachName">Поиск по категории...</span>    
                                </label>
                            </div>
                            <div className="categoriesSort">
                                <select className="catSortSelect" onChange={e => sort(e.target.value)} >
                                    <option>По умолчанию</option>
                                    <option value="asc">По возрастанию цены</option>
                                    <option value="desc">По убыванию цены</option>
                                </select>
                                <span className="catSortSelectArr"></span>
                            </div>
                        </div>
                        <div className="categoriesContentWrap">                               
                            <div className="categoryBrandsWrap">                                
                                <h3>Фильтрация по бренду</h3>
                                <div className="categoryBrandContent">
                                    {displayBrandsOutput}
                                </div>
                            </div>
                            <div className="categoryItemsBtnWrap">
                                <div className="categoryItemsWrap">
                                    {showProductList}
                                </div>
                                {/* {button} */}
                            </div>
                        </div>
                    </div>
                </div>
                {openQuick && <QuickModal closeModal={setOpenQuick} product_slug={quickProduct} category_slug={quickCategory} />}
                <div className="hFooter"></div>
            </main>
            <Footer />
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    );
}

export default OneCategory;
