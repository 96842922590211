import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CategoryImage from "../../../frontend/pages/CategoryImage";
import { addToCart } from "../../../features/cartSlice";
import { addToFav } from "../../../features/favSlice";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";


function QuickModal({ closeModal, product_slug, category_slug }){
    const [collection, setCollection] = useState(''); 
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit};
        dispatch(addToCart(newItem))
    }
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }

    const history = useHistory();
    const [product, setProduct] = useState([]);
    const [currentValue, setCurrentValue] = useState(''); //Какой сейчас выбран тип коробка или упаковка
    const [qty, setQty] = useState(''); //Текущее количество товара в input
    const [productAttr, setProductAttr] = useState([]);
    const [related, setRelated] = useState([]);
    const [category, setCategory] = useState([]);
    const favourite = useSelector ((state) => state.fav.favItems);
    const isItemInFavourite = favourite.some(item => item.id === product.id);

    //Все что косается коллекции
    const [collectionPrice1, setCollectionPrice1] = useState(''); // Цена первого товара за штуку
    const [collectionPrice2, setCollectionPrice2] = useState(''); // Цена второго товара за штуку
    const [collectionQty, setCollectionQty] = useState(''); // Текущее значение input
    const [collectionCurrentValue, setCollectionCurrentValue] = useState(''); // Текущее количество в упаковке    
    //Все что косается коллекции

    const [activeKor, setActiveKor] = useState(false);
    const [activeUp, setActiveUp] = useState(false);
    useEffect(() => {        
        setActiveKor(false);
        setActiveUp(false);
        let isMounted = true;
        
        
        axios.get(`/api/view-product/${category_slug}/${product_slug}`).then(res => {
            if(isMounted){                
                if(res.data.status === 200){
                    if(res.data.product.unit_up === null){
                        setCurrentValue(res.data.product.unit_kor);
                        setQty(res.data.product.unit_kor);                        
                        setActiveKor(true);
                        
                        if(res.data.collection !== '' && res.data.collection.length > 1){
                            setCollectionCurrentValue(res.data.collection[0].unit_kor);
                            setCollection(res.data.collection);
                            setCollectionQty(res.data.collection[0].unit_kor);
                            setCollectionPrice1(res.data.collection[0].price);
                            setCollectionPrice2(res.data.collection[1].price);
                        }else{
                            setCollection('');
                        }
                    }else{
                        setCurrentValue(res.data.product.unit_up);
                        setQty(res.data.product.unit_up);
                        setActiveUp(true);
                        
                        if(res.data.collection !== '' && res.data.collection.length > 1){
                            setCollectionCurrentValue(res.data.collection[0].unit_up);
                            setCollection(res.data.collection);
                            setCollectionQty(res.data.collection[0].unit_up);
                            setCollectionPrice1(res.data.collection[0].price);
                            setCollectionPrice2(res.data.collection[1].price);
                        }else{
                            setCollection('');
                        }
                    }
                    setProduct(res.data.product);
                    setCategory(res.data.category);
                    setRelated(res.data.related);
                    setProductAttr(res.data.attributes);                    
                    setLoading(false);
                }  else if( res.data.status === 404 ){
                    history.push('/catalog');
                    toast.error('Нет такой категории');
                }
            }
        });
        return () => {
            isMounted = false;
        }
    }, [category_slug, product_slug, history]);

    const handleDecrement = () =>{
        if(qty > currentValue){
            setQty((prevCount) => prevCount - currentValue);
        }
    }

    const handleIncrement = () =>{
        setQty((prevCount) => prevCount + currentValue);
    }

    const handleDecrementCollection = () =>{
        if(collectionQty > currentValue){
            setCollectionQty((prevCount) => prevCount - collectionCurrentValue);
        }
    }

    const handleIncrementCollection = () =>{
        setCollectionQty((prevCount) => prevCount + collectionCurrentValue);
    }

    const unitHandlerUp = (data) => {
        setCurrentValue(data);
        setQty(data);
        setActiveUp(true);
        setActiveKor(false);
    }

    const unitHandlerKor = (data) => {
        setCurrentValue(data);
        setQty(data);
        setActiveKor(true);
        setActiveUp(false);
    }

    const handleIncrementRetaled = (index, qty) => {
        const newItems = [...related];
        newItems[index].qty = newItems[index].qty + qty;
        newItems[index].quantity = qty;
        setRelated(newItems);
    }

    const handleDecrementRetaled = (index, qty) => {
        const newItems = [...related];
        if(newItems[index].qty > qty){
            newItems[index].qty = newItems[index].qty - qty;
            newItems[index].quantity = qty;
            setRelated(newItems);
        }
    }

    const handleSetQty = (type, idx) => {
        const newItems = [...related];
        if(newItems[idx].quantity === type){

        }else{
            newItems[idx].qty = type;
            newItems[idx].quantity = type;
            setRelated(newItems);
        }
    }

    const handleAddToCartCollect = () => {
        for (let i = 0; i < collection.length; i++) {
            const newItem = {...collection[i], quantity: collectionQty, whichUnit: collectionCurrentValue};
            dispatch(addToCart(newItem));
        }
    }

    return (
        <div className='modalBackground'>
            <div className='modalContainerQuickWrap'>
                <div className="modalContainerQuick">
                    <div className="quickProductImg">
                        <a href={`/uploads/products/${product.image}`} data-fancybox="group">
                            <img src={`/uploads/products/${product.image}`} className="cartImageMain" alt='' />
                            {product.status === 0 ? <div className="productInstock">В наличии</div> : <div className="productOutInstock">Нет в наличии</div>}
                        </a>
                        <Link to={`/catalog/${category_slug}/${product_slug}`}>
                            <div className="productOneClickBtn"><i className="fa-solid fa-hand-point-up productFavBtnI"></i>Подробнее о товаре</div>
                        </Link>  
                        <div className="productDetailsRemark">
                            {product.remark ? <div className="productImgRemark">{product.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                    </div>
                    <div className="quickRightBlock">
                        <button className="modalCloseBtn" onClick={()=> closeModal(false)}>X</button>
                        <div className="productDetailsContent">
                                <h1>{product.name}</h1>
                                <div className="productDetailsContentWrap">
                                    <div className="productDetailsLeft">
                                        <div className="productDetailsBrand"><strong>Артикул: </strong>{product.SKU}</div>
                                        <div className="productDetailsBrand"><strong>Бренд: </strong>{product.brand}</div>
                                        <div className="productDetailsBrand"><strong>Тип товара: </strong>{product.type}</div>
                                        <div className="productDetailsBrand"><strong>Цвет: </strong>{product.color}</div>
                                        {productAttr.material === null ? '' : <div className="productDetailsBrand"><strong>Материал: </strong>{productAttr.material}</div>}
                                        {productAttr.dimeter === null ? '' : <div className="productDetailsBrand"><strong>Диаметр: </strong>{productAttr.dimeter}</div>}
                                        {productAttr.volume === null ? '' : <div className="productDetailsBrand"><strong>Объем: </strong>{productAttr.volume}</div>}
                                        {productAttr.height === null ? '' : <div className="productDetailsBrand"><strong>Высота: </strong>{productAttr.height}</div>}
                                        {productAttr.width === null ? '' : <div className="productDetailsBrand"><strong>Ширина: </strong>{productAttr.width}</div>}
                                        {productAttr.length === null ? '' : <div className="productDetailsBrand"><strong>Длина: </strong>{productAttr.length}</div>}
                                        {productAttr.size === null ? '' : <div className="productDetailsBrand"><strong>Размер: </strong>{productAttr.size}</div>}                                        
                                    </div>
                                    <div className="productDetailsRight">
                                        {product.selling_price
                                        ?
                                        <div>
                                            <div className="homeProductItemPrice red">Цена: <strong>{parseInt(product.selling_price * qty)}</strong>₸<span>{product.selling_price}₸ / шт.</span></div>
                                            <div className="productDetailsSPriceSale">Старая цена: <strong>{product.price} <span>₸</span></strong> / шт.</div>
                                        </div>
                                        :
                                        <div className="productDetailsSPrice">Цена: <strong>{parseInt(product.price * qty)}</strong>₸ <span>{product.price} ₸ / шт.</span> </div>
                                        }
                                        <div className="productUnitWrap">
                                            {product.unit_up === null
                                            ? '' 
                                            :<button className={`productUnitUp ${activeUp ? 'active' : ''}`} onClick={() => unitHandlerUp(product.unit_up)}>Упаковка ({product.unit_up} шт.)</button>}
                                            {product.unit_kor === null 
                                            ? '' 
                                            : <button className={`productUnitKor ${activeKor ? 'active' : ''}`} onClick={() =>  unitHandlerKor(product.unit_kor)}>Коробка ({product.unit_kor} шт.)</button>}
                                        </div>
                                        <div className="productQty">Количество:</div>
                                        <div className="quantity-input">
                                            <div className="cartProdQty">
                                                <button className="cartMinus" onClick={handleDecrement}>-</button>
                                                <input
                                                    disabled
                                                    value={qty}
                                                    className="cartCountInp"
                                                    type={'text'}
                                                />
                                                <button className="cartPlus" onClick={handleIncrement}>+</button>
                                            </div>
                                        </div>     
                                                                    
                                    </div>
                                    <div className="productCartBtns">
                                        <button className="productCartBtn" onClick={() => handleAddToCart(product, qty, currentValue)} >
                                            <i className="fas fa-shopping-bag" aria-hidden="true"></i>Купить
                                        </button>                                      
                                    </div>
                                    <button className="productFavBtn" onClick={() => handleAddToFav(product)}>
                                        {isItemInFavourite
                                            ? <span className="favDelete"><i className="fas fa-heart" aria-hidden="true"></i>Убрать из избранного</span>
                                            : <span><i className="fas fa-heart" aria-hidden="true"></i>В избранное</span>
                                        }
                                    </button>
                                </div>
                            </div>
                    </div>
                </div>
                {collection === ""
                     ?   
                     <div></div>               
                    
                    :
                        <div className="detailsCollectWraper">
                            <h2>Коллекция</h2>
                            <div className="detailsCollectionWrap">
                                <div className="collectionItem">
                                    <img src={`/uploads/products/${collection[0].image}`} width='200' height='200' alt='' />
                                    <div className="collectionItemContent">
                                        <h3>{collection[0].name}</h3>
                                        <div className="homeProductItemPrice"><strong>{parseInt(collectionPrice1 * collectionQty)}</strong>₸<span>{collection[0].price}₸ / шт.</span></div>
                                    </div>                                
                                </div>
                                <span>+</span>
                                <div className="collectionItem">
                                    <img src={`/uploads/products/${collection[1].image}`} width='200' height='200' alt='' />
                                    <div className="collectionItemContent">
                                        <h3>{collection[1].name}</h3>
                                        <div className="homeProductItemPrice"><strong>{parseInt(collectionPrice2 * collectionQty)}</strong>₸<span>{collection[1].price}₸ / шт.</span></div>
                                    </div>
                                </div>
                                <span>=</span>
                                <div className="collectionCalc">
                                    <div className="cartProdQty">
                                        <button className="cartMinus" onClick={handleDecrementCollection}>-</button>
                                        <input
                                            disabled
                                            value={collectionQty}
                                            className="cartCountInp"
                                            type={'text'}
                                        />
                                        <button className="cartPlus" onClick={handleIncrementCollection}>+</button>
                                    </div>
                                    <button className="collectionBtn" onClick={() => handleAddToCartCollect()}>Купить коллекцию</button>
                                    <div className="collectionSumm"><span>Итог: </span>{(collectionPrice1 * collectionQty) + (collectionPrice2 * collectionQty)}</div>
                                </div>
                            </div>
                        </div>
                    } 
            </div>
            <div className="modalOverlayQuick" onClick={()=> closeModal(false)}></div>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    )
}

export default QuickModal;