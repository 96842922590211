import { createSlice } from '@reduxjs/toolkit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialState = {
    cartItems: localStorage.getItem('cartItems') 
    ? JSON.parse(localStorage.getItem('cartItems')) 
    : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
}

const cartSlice = createSlice ({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action){
            const itemIndex = state.cartItems.findIndex((item) => item.id === action.payload.id); // Find index in state and match with incoming item.id
            
            if(itemIndex >= 0){
                state.cartItems[itemIndex].cartQuantity += action.payload.quantity;
                state.cartItems[itemIndex].whichUnit = action.payload.whichUnit;
                toast.info('Количество изменено', {
                    position:'bottom-left'
                })
            }else {                
                const tempProduct = {...action.payload, cartQuantity: action.payload.quantity};
                state.cartItems.push(tempProduct);
                toast.success(`${action.payload.name} добавлен(о) в корзину`, {
                    position:'top-center',
                });
            }
            
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems));

        },
        
        removeFromCart(state, action){
            const nextCartItems = state.cartItems.filter(
                (cartItem) => cartItem.id !== action.payload.id
            )
            state.cartItems  = nextCartItems;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))

            toast.error(`${action.payload.name} удален из корзины`, {
                position:"bottom-left"
            });
        },

        decreaseCart( state, action ){
            const itemIndex = state.cartItems.findIndex(
                (cartItem) => cartItem.id === action.payload.id
            )
                console.log()
            if(state.cartItems[itemIndex].cartQuantity > state.cartItems[itemIndex].whichUnit){//Если больше 1 то уменьшаем на 1
                state.cartItems[itemIndex].cartQuantity = state.cartItems[itemIndex].cartQuantity - state.cartItems[itemIndex].whichUnit;
                localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            } else{
                state.cartItems[itemIndex].cartQuantity = action.payload.whichUnit;                
            }         
        },

        increaseCart( state, action ){
            const itemIndex = state.cartItems.findIndex(
                (cartItem) => cartItem.id === action.payload.id
            )
            state.cartItems[itemIndex].cartQuantity += action.payload.whichUnit;            
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));        
        },

        clearCart( state, action ){
            state.cartItems = [] //Все товары в корзине удаляем

            toast.error(`Корзина была очищена`, {
                position:"bottom-left"
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems))
        },

        getTotals (state, action){
            let {total, quantity} = state.cartItems.reduce(
                (cartTotal, cartItem) => {
                    if(cartItem.selling_price){
                        const { selling_price, cartQuantity} = cartItem;
                        const itemTotal = parseInt(selling_price * cartQuantity);

                        cartTotal.total += itemTotal;
                        cartTotal.quantity += cartQuantity;

                        return cartTotal;
                    }else{
                        const { price, cartQuantity} = cartItem;
                        const itemTotal = parseInt(price * cartQuantity);

                        cartTotal.total += itemTotal;
                        cartTotal.quantity += cartQuantity;

                        return cartTotal;
                    }
            }, {
                total: 0,
                quantity: 0,
            });
            state.cartTotalQuantity = quantity;
            state.cartTotalAmount = total;
        },

        setQty (state, action){            
            const itemIndex = state.cartItems.findIndex(
                (cartItem) => cartItem.id === action.payload.id
            )
            state.cartItems[itemIndex].cartQuantity = action.payload.cartQty;
            state.cartItems[itemIndex].whichUnit = action.payload.cartQty;
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
    },
});
<ToastContainer
    position="top-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
/>
export const { addToCart, removeFromCart, decreaseCart, clearCart, getTotals, addToCartMany, increaseCart, setQty } = cartSlice.actions;

export default cartSlice.reducer;
