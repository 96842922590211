import React from "react";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled from '@mui/base/TabUnstyled';
import SaleProduct from './SaleProduct';
import NewsProduct from './NewsProduct';
import BestProduct from './BestProduct';

function HomeSlickProducts(){
    return (
        <TabsUnstyled defaultValue={0}>
            <TabsListUnstyled className="homeTabs">
              <TabUnstyled className="homeTabsBtn">Акции</TabUnstyled>
              <TabUnstyled className="homeTabsBtn">Новинки</TabUnstyled>
              <TabUnstyled className="homeTabsBtn">Хиты продаж</TabUnstyled>
            </TabsListUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={0}>
              <SaleProduct/>
            </TabPanelUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={1}>
              <NewsProduct/>
            </TabPanelUnstyled>
            <TabPanelUnstyled className="homeTabsCat" value={2}>
              <BestProduct/>
            </TabPanelUnstyled>
          </TabsUnstyled>
    );
}

export default HomeSlickProducts;