import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart } from "../../features/cartSlice";
import CallBack from "./CallBack/Modal";

function Home() {
    const [openMenu, setOpenMenu] = useState(false);
    const [scrollFix, setScrollFix] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const cart = useSelector ((state) => state.cart); // Getting cart object from state
    const favourite = useSelector ((state) => state.fav.favItems); // Getting favourite object from state
    const history = useHistory();// Redirect
    const [searchInput, setSearchInput] = useState('none');
    const [searchWord, setSearchWord] = useState('');
    const [searchFilteringData, setSearchFilteringData] = useState([]);
    const [category, setCategory] = useState([]); // API object of sections and categories

    
    const [settings, setSettings] = useState([]);
    const [phoneNumber, setPhone] = useState('');

    useEffect(() => {
        axios.get(`/api/view-settings`).then(res => {
            if (res.data.status === 200) {
                setSettings(res.data.setting);
                setSearchWord('');
            }
        }).catch();
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const dispatch = useDispatch();
    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem)); // Changing state of cart items - deleting item row by id
    }


    const menuHandler = () => {
        setOpenMenu(!openMenu);
    }
    //Search
    const searchHandler = async (event) => {
        const searchWord = event.target.value;
        setSearchWord(searchWord);
        if (searchWord.length > 3) {

            const { data } = await axios.get(`/api/searchresult/${searchWord}`);       
            if(data.products){
                setSearchFilteringData(data.products.slice(0,30));
                setSearchInput('block');
            }
        } else {
            setSearchFilteringData([]);
            setSearchInput('none');
        }
    }
    
    let searchList = '';
    searchList = searchFilteringData.slice(0, 10).map((item, idx) => {
        return (
            <div key={idx}>
                { item.searchCategory === null ? ' ' :
                    <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`} className="headerSearchItem">
                        <img className="homeMenuImg" src={`/uploads/products/${item.image}`} height={30} width={30} alt={item.name} />
                        <p>{item.name}</p>
                    </Link>
                }
            </div>
        )
    });// end search


    const logoutSubmit = (e) => {
        e.preventDefault();

        axios.post(`/api/logout`).then(res => {
            if (res.data.status === 200) {
                localStorage.removeItem('auth_token', res.data.token);
                localStorage.removeItem('auth_name', res.data.username);
                localStorage.removeItem('role', res.data.role);

                history.push('/');
                swal("TGR Market", res.data.message, 'success');
            } else {

            }
        }).catch(function (error) {
            if(error.response.status==401){
                  localStorage.clear();
                  history.push('/login');
            }
          });
    }
    const handleSearchBtn = () => {
        history.push(`/searchresult/${searchWord}`);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`/api/getCategories`).then(res => {
            if (res.data.status === 200) {
                setCategory(res.data.category);
            }
        });
    }, []);

    //Category menu
    let categoryList = '';
    categoryList = category.map((item, idx) => {
        return (
            <Link to={`/catalog/${item.slug}`} className="homeCategoryItem" key={idx} onClick={menuHandler}>
                <img className="homeCategoryImg" src={`/uploads/categories/${item.image}`} alt="" />
                <p>{item.name}</p>
            </Link>
        )
    });

    let buttons = '';
    if (localStorage.getItem('auth_token')) {
        buttons = (
            <div>
                <h3>Здравствуйте, { localStorage.getItem('auth_name') }.</h3>
                <ul>
                    <li><Link to="/logout" onClick={logoutSubmit}>Выход</Link></li> / <li><Link to="/dashboard">Кабинет</Link></li>
                </ul>
            </div>
        )
    } else {
        buttons = (
            <div>
                <h3>Ваш личный кабинет</h3>
                <ul>
                    <li><Link to="/login">Вход</Link></li> / <li><Link to="/register">Регистрация</Link></li>
                </ul>
            </div>
        )
    }//end Logout
    if(width <= 768){

    }else{
        const changeValueOnScroll = () => {   
            const scrollValue = document.documentElement.scrollTop;
            if (scrollValue > 370) {
                setScrollFix(true);
            } else {
                setScrollFix(false);
            }        
        }//End scroll event
        window.addEventListener ('scroll', changeValueOnScroll);
    }
    return (
        <header>
            <div className="headerTop">
                <div className="headerTopNavWrap">
                    <div className="container">
                        <div className="headerTopNavWrapper">
                            <div className="headerTopNav">
                                <ul>
                                    <li>
                                        <Link to='/delivery'>Доставка и оплата</Link>
                                    </li>
                                    <li>
                                        <Link to='/about'>О магазине</Link>
                                    </li>
                                    <li>
                                        <Link to='/brands'>Бренды</Link>
                                    </li>
                                    <li>
                                        <Link to='/articles'>Статьи и новости</Link>
                                    </li>
                                    <li>
                                        <Link to='/contacts'>Контакты</Link>
                                    </li>
                                </ul>
                            </div>  
                            <div className="headerTopPhonesAddress">
                                <a href="#" className="headerTopAddress">
                                    <i className="fas fa-map-marker-alt"></i>
                                    ул. Ратушного, 16 а.
                                </a>
                                <a href="tel:+77473333347" className="headerTopPhone">
                                    <i className="fas fa-phone-volume" aria-hidden="true"></i>
                                    <span>+7 747 </span>333 33 47
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="headerCenterContent">
                    <div className="container">
                        <div className="headerCenterWrapper">
                            {/* Logo slogan */}
                            <div className="headerCenterLogo">
                                <Link className="headerCenterLogoA" to="/"><img src={`/uploads/design/tgrLogo.jpg`} /><p>TGR MARKET ALMATY</p></Link>
                            </div>
                            {/* Title search */}
                            <div className="headerTitleSearch">
                                <h2>Интернет магазин одноразовой посуды</h2>
                                <p>по ценам производителя без посредников</p>
                                <div className='headerForm'>
                                    <form >
                                        <input 
                                            type="text" 
                                            required
                                            placeholder='Поиск по каталогу...'
                                            onChange={searchHandler}
                                            onBlur={() =>
                                                setTimeout(() => {
                                                    setSearchInput('none');
                                                }, 100)
                                            }
                                        />
                                        <button type="submit" onClick={searchWord === '' ? null : handleSearchBtn}><i className="fas fa-search"></i><span>Поиск</span></button>
                                        <div className="headerSearchResult" style={{display: searchInput}} >
                                            {searchList}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* Social */}
                            <div className="headerSocialWrap">
                                <a className="headerSocialWrapWhats" target='_blank' href='https://wa.me/+77473333347'>
                                    <img src={`/uploads/design/whatAppHeader.jpg`} />
                                </a>
                                <p>Мы в соц сетях</p>
                                <div className="headerSocial">
                                    <a href="#"><i className="fab fa-facebook-square"></i></a>
                                    <a href="#"><i className="fab fa-vk"></i></a>
                                    <a href="#"><i className="fab fa-instagram-square"></i></a>
                                </div>
                            </div>
                            {/* Cart Favourite */}
                            <div className="headerCartFavourite">
                                <div className="headerRegistrationWrap">
                                    <i className="fa fa-sign-out"></i>
                                    <div className="headerRegistration">
                                        {buttons}
                                    </div>
                                </div>
                                <div className="headerCartFavBtns">
                                    <div className="headerCartBtn">
                                        <Link to="/cart">
                                            <div className="headerCart">
                                                <i className="fa-solid fa-basket-shopping"></i>
                                                <p>{cart.cartItems.length}</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="headerFavBtn">
                                        <Link to="/favourite">
                                            <div className="headerCart">
                                                <i className="fa-sharp fa-solid fa-star"></i>
                                                <p>{favourite.length}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="headerBtn" onClick={() => setOpenModal(true)}>
                                    Перезвоните мне
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="headerNavigation">
                    <div className="headerNavigationWrap">
                        <div className="headerCatalogBtn" onClick={menuHandler}>
                            <div className={`header__burger ${openMenu ? 'active' : ''}`}>
                                <span></span>
                            </div>                            
                            Каталог товаров
                        </div>
                        <div className={`header__menu ${openMenu ? 'active' : ''}`}>
                            {categoryList}
                        </div>
                    </div>
                    <nav>
                        <ul>
                            <li><Link to="/news">Новинки</Link></li>
                            <li><Link to="/sale">Акции</Link></li>
                            <li><Link to="/hits">Хит продаж</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>            
            {openModal && <CallBack closeModal={setOpenModal} />}
            <div className={`${openMenu ? 'modalOverlayMy' : ''}`} onClick={menuHandler}></div>
            <div className="mobileFooter" id="mobileFooter"></div>
            <div className="mobileHeader" id="mobileHeader"></div>
            <div id="mobileHeaderPhone">
                <a href='tel:+77473333347'>
                   <i className="fas fa-phone-square-alt"></i>
                </a>
            </div>
        </header>
    );
}

export default Home;