import React, { useState, useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import publicRoutesList from '../../routes/PublicRouteList';
import axios from "axios";



const FrontendLayout = () => {
    const [isActive, setIsActive] = useState(false);
    const [category, setCategory] = useState([]); // API object of sections and categories

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`/api/getCategories`).then(res => {
            if (res.data.status === 200) {
                setCategory(res.data.category);
            }
        });
    }, []);
    const handleMenu = event => {
        setIsActive(current => !current);
    }
    //Category menu
    let categoryList = '';
    categoryList = category.map((item, idx) => {
        return (
            <Link to={`/catalog/${item.slug}`} className="mobileNavItem" key={idx} onClick={handleMenu}>
                <img className="homeCategoryImg" src={`/uploads/categories/${item.image}`} alt="" />
                <p>{item.name}</p>
            </Link>
        )
    });
      

    

    return (
        <div>
            <nav className={`mobileShow ${isActive ? 'menuActive' : ''}`}>
                <div id="menuBtnWrap" onClick={handleMenu}>
                    <div className={`menuBtn ${isActive ? 'menuIconActive' : ''}`}></div>
                </div>
                <div id='destinationMove'>
                    {categoryList}
                        <div className="mobileTopInfo">
                                <a href="https://2gis.kz/almaty/search/%D1%83%D0%BB.%20%D0%A0%D0%B0%D1%82%D1%83%D1%88%D0%BD%D0%BE%D0%B3%D0%BE%2C%2016%20%D0%B0/geo/9430047374987734/76.91048%2C43.284162?m=76.910499%2C43.284183%2F18" className="headerTopAddress">
                                    <i className="fas fa-map-marker-alt"></i>
                                    ул. Ратушного, 16 а.
                                </a>
                                <a href="tel:+77473333347" className="headerTopPhone">
                                    <i className="fas fa-phone-volume" aria-hidden="true"></i>
                                    <span>+7 747 </span>333 33 47
                                </a>
                                <a href="mailto:info@tgrmarket.kz"><i className="fas fa-envelope" aria-hidden="true"></i> info@tgrmarket.kz</a>
                        </div>
                </div>
            </nav>
            <div id="layoutSidenav" className={`mainContentWrap ${isActive ? 'mainContentActive' : ''}`}>            
                <Switch>
                    {publicRoutesList.map((routedata, i) => {
                        return (
                            routedata.component && (
                                <Route
                                    key={i}
                                    path={routedata.path}
                                    exact={routedata.exact}
                                    name={routedata.name}
                                    render={(props) => (
                                        <routedata.component {...props} />
                                    )}
                                />
                            )
                        )
                    })}
                </Switch>
            </div>
        </div>
    );
}

export default FrontendLayout;
