import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddProduct() {

    const [productInput, setProductInput] = useState({
        name: '',
        status: '',
        remark: '',
        selling_price: '',
        price: '',
        SKU: '',
        unit_up: '',
        unit_kor: '',
        description: '',
        image: '',
        type: '',
        color: '',
        priority: '',
        qty: '',
        meta_title: '',
        meta_keywords: '',
        meta_description: '',
    }); //Get all input data

    const [productInputAttr, setProductInputAttr] = useState({
        material: '',
        dimeter: '',
        volume: '',
        height: '',
        width: '',
        length: '',
        size: '',
    }); //Get all input data

    
    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setProductInput({ ...productInput, [e.target.name]: e.target.value });
    }

    const handleInputAttr = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setProductInputAttr({ ...productInputAttr, [e.target.name]: e.target.value });
    }

    const submitProduct = (e) => {
        e.preventDefault();

        const data = {
            name: productInput.name,
            brand: productInput.brand,
            category_id: productInput.category_id,
            remark: productInput.remark,
            selling_price: productInput.selling_price,
            price: productInput.price,
            SKU: productInput.SKU,
            unit_up: productInput.unit_up,
            unit_kor: productInput.unit_kor,
            description: productInput.description,
            image: productInput.image,
            type: productInput.type,
            color: productInput.color,
            qty: productInput.qty,
            priority: productInput.priority,
            meta_title: productInput.meta_title,
            meta_keywords: productInput.meta_keywords,
            meta_description: productInput.meta_description,

            material: productInputAttr.material,
            dimeter: productInputAttr.dimeter,
            volume: productInputAttr.volume,
            height: productInputAttr.height,
            width: productInputAttr.width,
            length: productInputAttr.length,
            size: productInputAttr.size,
        };


        axios.post(`/api/add-product`, data).then(res => {
            if(res.data.status === 200){
                swal("Товар успешно создан!", res.data.message, 'success');
                document.getElementById('PRODUCT_FORM').reset();
            } else if(res.data.status === 400){
                toast.error('Не все поля заполнены');
                setProductInput({ ...productInput, error_list:res.data.errors }); //Записываем ошибки в массив
            }
        });
    }

    return (
        <div className="container-fluid px-4">
            <form onSubmit={submitProduct} id="PRODUCT_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="attributes-tab" data-bs-toggle="tab" data-bs-target="#attributes" type="button" role="tab" aria-controls="attributes" aria-selected="false">Атрибуты товара</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Название товара</b></label>
                            <input type="text" name="name" onChange={handleInput} value={ productInput.name } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Категория товара</b></label>
                            <input type="text" name="category_id" onChange={handleInput} required value={ productInput.category_id } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Бренд</b></label>
                            <input type="text" name="brand" onChange={handleInput} required value={ productInput.brand } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Артикул товара</b></label>
                            <input type="text" name="SKU" onChange={handleInput} value={productInput.SKU } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Количество в упаковке</b></label>
                            <input type="text" name="unit_up" onChange={handleInput} value={productInput.unit_up} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Количество в коробке</b></label>
                            <input type="text" name="unit_kor" onChange={handleInput} value={productInput.unit_kor } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Цена обычная</b></label>
                            <input type="text" name="price" onChange={handleInput} value={productInput.price } className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Цена распродажи</b></label>
                            <input type="text" name="selling_price" onChange={handleInput} value={productInput.selling_price} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Описание товара</b></label>
                            <textarea type="text" name="description" onChange={handleInput} value={productInput.description} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Изображение товара</b></label>
                            <input
                                type="text"
                                name="image"
                                onChange={handleInput}
                                className="form-control"
                                value={productInput.image} 
                            />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Тренд</b> - (sale, best, new)</label>
                            <input type="text" name="remark" onChange={handleInput} value={productInput.remark} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Тип товара</b></label>
                            <input type="text" name="type" onChange={handleInput} value={productInput.type} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Цвет товара</b></label>
                            <input type="text" name="color" onChange={handleInput} value={productInput.color} className="form-control" />
                        </div>

                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Количество товара</b></label>
                            <input type="text" name="qty" onChange={handleInput} value={productInput.qty} className="form-control" />
                        </div>     
                        <div className="form-group mb-2">
                            <label className="mb-2 mt-2"><b>Приоритет сортировки</b></label>
                            <input type="text" name="priority" onChange={handleInput} value={productInput.priority} className="form-control" />
                        </div>                    
                    </div>
                    <div className="tab-pane fade" id="attributes" role="tabpanel" aria-labelledby="attributes-tab">

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Материал</b></label>
                            <input type="text" name="material" onChange={handleInputAttr} value={productInputAttr.material} className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Диаметр</b></label>
                            <input type="text" name="dimeter" onChange={handleInputAttr} value={productInputAttr.dimeter} className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Объем</b></label>
                            <input type="text" name="volume" onChange={handleInputAttr} value={productInputAttr.volume } className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Высота</b></label>
                            <input type="text" name="height" onChange={handleInputAttr} value={productInputAttr.height} className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Ширина</b></label>
                            <input type="text" name="width" onChange={handleInputAttr} value={productInputAttr.width} className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Длина</b></label>
                            <input type="text" name="length" onChange={handleInputAttr} value={productInputAttr.length} className="form-control" />
                        </div>

                        <div className="form-group mb-3 mt-2">
                            <label className="mb-2"><b>Размер</b></label>
                            <input type="text" name="size" onChange={handleInputAttr} value={productInputAttr.size} className="form-control" />
                        </div>

                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={productInput.meta_title} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={productInput.meta_keywords} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={productInput.meta_description} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default AddProduct
