import React from "react";
import { Link } from 'react-router-dom';


const Navbar = () => {

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">

            <Link className="navbar-brand ps-3" to="/" target='_blank'>
                {/* <img src="/uploads/design/adminLogo.png" /> */}
            </Link>

            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fas fa-user fa-fw"></i>12
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><Link to="#" className="dropdown-item">Settings</Link></li>
                        <li><Link to="#" className="dropdown-item">Activity Log</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="#" className="dropdown-item">Logout</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
    )

}

export default Navbar;