import React from 'react';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from "react-redux";
import cartReducer, { getTotals } from './assets/features/cartSlice';
import favReducer from './assets/features/favSlice';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    fav: favReducer,
  },
})
store.dispatch(getTotals());


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
     <App />
     </Provider>
  </BrowserRouter>
);


reportWebVitals();
