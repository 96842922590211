import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Category(){
    
    const [categoryInput, setCategory] = useState({
        name: '',
        meta_title: '',
        meta_keywords: '',
        meta_description: '',
        error_list: [],
    }); //Get all input data

    

    const handleInput = (e) => {
        // Останавливает React от сброса свойств объекта события:
        e.persist();
        setCategory({ ...categoryInput, [e.target.name]: e.target.value });
    }



    const submitCategory = (e) => {
        e.preventDefault();

        const data = {
            name:  categoryInput.name,         
            meta_title:  categoryInput.meta_title,
            meta_keywords:  categoryInput.meta_keywords,
            meta_description:  categoryInput.meta_description,
        };

        axios.post(`/api/store-category`, data).then(res => {
            if(res.data.status === 200){
                swal("Категория успешно создана!", res.data.message, 'success');
                document.getElementById('CATEGORY_FORM').reset();
            } else if(res.data.status === 400){
                toast.error('Не все поля заполнены');
                setCategory({ ...categoryInput, error_list:res.data.errors }); //Записываем ошибки в массив
            }
        });
    }


    return (
        <div className="container-fluid px-4">
            <h1 className="mt-4 mb-5">Добавить категорию</h1>
            <form onSubmit={submitCategory} id="CATEGORY_FORM">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Основные параметры</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="seo-tags-tab" data-bs-toggle="tab" data-bs-target="#seo-tags" type="button" role="tab" aria-controls="seo-tags" aria-selected="false">Теги для СЕО</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-group mb-3">
                            <label className="mb-2 mt-5"><b>Название категории <span className="red">*</span></b></label>
                            <input type="text" required name="name" onChange={handleInput} value={categoryInput.name} className="form-control" />
                        </div>                                            
                    </div>
                    <div className="tab-pane fade" id="seo-tags" role="tabpanel" aria-labelledby="seo-tags-tab">
                        <div className="form-group mb-3 mt-5">
                            <label className="mb-2"><b>Заголовок для SEO</b></label>
                            <input type="text" name="meta_title" onChange={handleInput} value={categoryInput.meta_title} className="form-control" />
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Ключевые слова SEO</b></label>
                            <textarea type="text" name="meta_keywords" onChange={handleInput} value={categoryInput.meta_keywords} className="form-control" ></textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label className="mb-2"><b>Описание SEO</b></label>
                            <textarea type="text" name="meta_description" onChange={handleInput} value={categoryInput.meta_description} className="form-control" ></textarea>
                        </div>
                    </div>
                </div>
                <p className="red">Поля помеченные * обязательные!</p>
                <button type="submit" className="btn btn-primary px-4 float-end">Сохранить</button>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Category;