import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function HomeSeoText() {

    return (
        <Fragment>
            <div className="homeAboutCompany">
                    <h3>Одноразовая посуда от команды "TGR Company"</h3>
                    <p>Добро пожаловать в мир удобства и экологической ответственности с нашей одноразовой посудой! Мы предлагаем широкий ассортимент высококачественных товаров, созданных для того, чтобы сделать ваше мероприятие или повседневное использование легкими и беззаботными.</p><br/>
                    <p>Наша одноразовая посуда сочетает в себе стиль, удобство и экологическую дружелюбность. Мы стремимся к тому, чтобы каждый наш продукт был не только функциональным, но и устойчивым к воздействию нашей планеты. Используя инновационные материалы, мы создаем продукцию, которая разлагается быстро и безопасно, минимизируя негативное воздействие на окружающую среду.</p><br/>
                    <p>Независимо от того, проводите ли вы вечеринку, праздник, пикник или просто хотите сэкономить время на уборке после обеда, наша одноразовая посуда идеально подходит для всех случаев. У нас вы найдете столовые приборы, тарелки, стаканы и многое другое в разнообразных цветах и дизайнах, чтобы ваш стол выглядел стильно и ухоженно.</p><br/>
                    <p>Выбирайте удобство и заботу о планете – выбирайте нашу одноразовую посуду. Позвольте вашему мероприятию сиять, не беспокоясь о посуде – мы об этом позаботимся!"</p>
            </div>
        </Fragment>
    )
}

export default HomeSeoText;