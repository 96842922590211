import React, { useState } from "react";
import { useForm } from "react-hook-form";
import './Modal.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

function Modal( { closeModal, productName } ) {   
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        await axios.post(`http://tgrmarket.kz/mail/mail.php`, data).then(response => {
            if(response.status === 200){
                toast.success('Ваша заявка принята!');
                closeModal(false);
            }
        })
        .catch(function (error) {
            toast.error('Заявка не отправлена!');
        });
    };
    

    return (
        <div className='modalBackground'>
            <div className='modalContainer'>
                <button className="modalCloseBtn" onClick={()=> closeModal(false)}>X</button>
                <h2>{productName}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="hidden"
                        id="name"
                        name="product"
                        value={productName}
                        {...register("product")}
                    />
                    <fieldset className="inputField">
                        <i class="fa-solid fa-user"></i>
                        <input
                            required
                            className="modalInput"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Введите Ваше имя"
                            {...register("name")}
                        />
                    </fieldset>                    
                    <fieldset className="inputField">
                        <i class="fa-solid fa-phone-volume"></i>
                        <input
                            required
                            className="modalInput"
                            type="phone"
                            id="phone"
                            name="phone"
                            placeholder="Введите Ваш телефон"
                            {...register("phone")}
                        />
                    </fieldset>        
                    <textarea
                        className="modalContactTextarea"
                        id="subject"
                        name="subject"
                        placeholder="Комментарий"
                        {...register("comment")}
                    ></textarea>
                    <input type="submit" className="modalInputBtn" value="Отправить" />
                </form>                
            </div>
            <div className="modalOverlay" onClick={()=> closeModal(false)}></div>
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    )
}

export default Modal
