import React, { useEffect, useState } from "react";
import Header from './Header';
import Footer from './Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToCart } from "../../features/cartSlice";
import { addToFav } from "../../features/favSlice";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "../../frontend/pages/skeleton";
import Modal from "./Modal/Modal";
import CategoryImage from "../../frontend/pages/CategoryImage";
import QuickModal from "../../components/frontend/Modal/QuickModal";




function ProductDetails(props){
    const [openQuick, setOpenQuick] = useState(false);
    const [quickProduct, setQuickProduct] = useState('');
    const [quickCategory, setQuickCategory] = useState(''); 
    const [collection, setCollection] = useState(''); 
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const handleAddToCart = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit};
        dispatch(addToCart(newItem))
    }
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }

    const history = useHistory();
    const [product, setProduct] = useState([]);
    const [currentValue, setCurrentValue] = useState(''); //Какой сейчас выбран тип коробка или упаковка
    const [qty, setQty] = useState(''); //Текущее количество товара в input
    const [productAttr, setProductAttr] = useState([]);
    const [related, setRelated] = useState([]);
    const [category, setCategory] = useState([]);
    const favourite = useSelector ((state) => state.fav.favItems);
    const isItemInFavourite = favourite.some(item => item.id === product.id);

    //Все что косается коллекции
    const [collectionPrice1, setCollectionPrice1] = useState(''); // Цена первого товара за штуку
    const [collectionPrice2, setCollectionPrice2] = useState(''); // Цена второго товара за штуку
    const [collectionQty, setCollectionQty] = useState(''); // Текущее значение input
    const [collectionCurrentValue, setCollectionCurrentValue] = useState(''); // Текущее количество в упаковке    
    //Все что косается коллекции

    const [activeKor, setActiveKor] = useState(false);
    const [activeUp, setActiveUp] = useState(false);
    
    useEffect(() => {        
        setActiveKor(false);
        setActiveUp(false);
        let isMounted = true;
        window.scrollTo(0, 0);
        const product_slug = props.match.params.product;
        const category_slug = props.match.params.category;
        
        axios.get(`/api/view-product/${category_slug}/${product_slug}`).then(res => {
            if(isMounted){                
                if(res.data.status === 200){
                    if(res.data.product.unit_up === null){
                        setCurrentValue(res.data.product.unit_kor);
                        setQty(res.data.product.unit_kor);                        
                        setActiveKor(true);
                        
                        if(res.data.collection !== '' && res.data.collection.length > 1){
                            setCollectionCurrentValue(res.data.collection[0].unit_kor);
                            setCollection(res.data.collection);
                            setCollectionQty(res.data.collection[0].unit_kor);
                            setCollectionPrice1(res.data.collection[0].price);
                            setCollectionPrice2(res.data.collection[1].price);
                        }else{
                            setCollection('');
                        }
                    }else{
                        setCurrentValue(res.data.product.unit_up);
                        setQty(res.data.product.unit_up);
                        setActiveUp(true);
                        
                        if(res.data.collection !== '' && res.data.collection.length > 1){
                            setCollectionCurrentValue(res.data.collection[0].unit_up);
                            setCollection(res.data.collection);
                            setCollectionQty(res.data.collection[0].unit_up);
                            setCollectionPrice1(res.data.collection[0].price);
                            setCollectionPrice2(res.data.collection[1].price);
                        }else{
                            setCollection('');
                        }
                    }
                    setProduct(res.data.product);
                    setCategory(res.data.category);
                    setRelated(res.data.related);
                    setProductAttr(res.data.attributes);                    
                    setLoading(false);
                }  else if( res.data.status === 404 ){
                    history.push('/catalog');
                    toast.error('Нет такой категории');
                }
            }
        });
        return () => {
            isMounted = false;
        }
    }, [props.match.params.category, props.match.params.product, history]);

    const handleDecrement = () =>{
        if(qty > currentValue){
            setQty((prevCount) => prevCount - currentValue);
        }
    }

    const handleIncrement = () =>{
        setQty((prevCount) => prevCount + currentValue);
    }

    const handleDecrementCollection = () =>{
        if(collectionQty > currentValue){
            setCollectionQty((prevCount) => prevCount - collectionCurrentValue);
        }
    }

    const handleIncrementCollection = () =>{
        setCollectionQty((prevCount) => prevCount + collectionCurrentValue);
    }

    const unitHandlerUp = (data) => {
        setCurrentValue(data);
        setQty(data);
        setActiveUp(true);
        setActiveKor(false);
    }

    const unitHandlerKor = (data) => {
        setCurrentValue(data);
        setQty(data);
        setActiveKor(true);
        setActiveUp(false);
    }

    const handleIncrementRetaled = (index, qty) => {
        const newItems = [...related];
        newItems[index].qty = newItems[index].qty + qty;
        newItems[index].quantity = qty;
        setRelated(newItems);
    }

    const handleDecrementRetaled = (index, qty) => {
        const newItems = [...related];
        if(newItems[index].qty > qty){
            newItems[index].qty = newItems[index].qty - qty;
            newItems[index].quantity = qty;
            setRelated(newItems);
        }
    }

    const handleSetQty = (type, idx) => {
        const newItems = [...related];
        if(newItems[idx].quantity === type){

        }else{
            newItems[idx].qty = type;
            newItems[idx].quantity = type;
            setRelated(newItems);
        }
    }

    const handleAddToCartCollect = () => {
        for (let i = 0; i < collection.length; i++) {
            const newItem = {...collection[i], quantity: collectionQty, whichUnit: collectionCurrentValue};
            dispatch(addToCart(newItem));
        }
    }

    const quickHandler = (product_slug, category_slug) => {
        setOpenQuick(!openQuick);
        setQuickProduct(product_slug);
        setQuickCategory(category_slug);
    }


    var showProductList = '';
    if(loading){
        showProductList = <Skeleton type="reel" />;
    }else{
        showProductList = related.map((item, idx) => { //Maping the result of filtering
            let defaultItem;
            if(item.quantity !== undefined){
                defaultItem = item.quantity; 
            }else{
                defaultItem = item.unit_up === null ? item.unit_kor : item.unit_up;
            }
            return(
                <div className="homeProductItem" key={idx}>
                <div className="productFront">
                    <div className="quickViewProduct" onClick={() => quickHandler(item.searchSlug, item.searchCategory.slug)}><i class="fa-sharp fa-solid fa-eye"></i>Быстрый просмотр</div> 
                    {item.status === 0 ? <div className="productInstock">В наличии</div> : <div className="productOutInstock">Нет в наличии</div>}
                    <div className="homeProductItemImg">
                        <Link to={`/catalog/${item.searchCategory.slug}/${item.slug}`}>
                            <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                        </Link>
                        {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                    </div>
                    <div className="homeProductItemInfo">
                        {item.selling_price
                        ?
                        <div>
                            <div className="homeProductItemPrice red"><strong>{parseInt(item.selling_price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.selling_price}₸ / шт.</span></div>
                            <div className="productDetailsSPriceSale"><strong>{item.price} <span>₸</span></strong> / шт.</div>
                        </div>
                        :
                        <div className="productDetailsSPrice"><strong>{parseInt(item.price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.price}₸ / шт.</span> </div>
                        }
                    </div>
                    <Link to={`/catalog/${item.searchCategory.slug}/${item.slug}`}><h2 className="productH2">{item.name}</h2></Link>
                    <div className='cartUnitType'>
                        {item.unit_up === null ? '' :<button className={`${item.unit_up === defaultItem ? 'active' : ''}`} onClick={() => handleSetQty(item.unit_up, idx)}>уп. ({item.unit_up} шт.)</button>}
                        {item.unit_kor === null ? '' : <button className={`${item.unit_kor === defaultItem ? 'active' : ''}`} onClick={() =>  handleSetQty(item.unit_kor, idx)}>кор. ({item.unit_kor} шт.)</button>}
                    </div>
                    <div className="productQty">
                        <button className="cartMinus" onClick={() =>  handleDecrementRetaled(idx, item.quantity === undefined ? defaultItem : item.quantity)}>-</button>
                        <div>
                            {item.qty === 0 ? item.qty = defaultItem : item.qty}
                        </div>
                    
                        <button className="cartPlus" onClick={() =>  handleIncrementRetaled(idx, item.quantity === undefined ? defaultItem : item.quantity)}>+</button>
                    </div>

                    <div className="catProductBtn">
                        <button
                            className="catProdAdd cartBtnProduct"
                            onClick={() => handleAddToCart(item, item.qty, item.quantity === undefined ? defaultItem : item.quantity)}
                        ><i className="fa-solid fa-basket-shopping"></i> Купить</button>
                        <button
                            onClick={() => handleAddToFav(item)}
                            className="catProdAdd favBtnProduct"
                        ><i className="fa-sharp fa-solid fa-star"></i></button>
                    </div>
                    <div className="productBackground"></div>
                </div>
            </div>
            )
        });
    }
    return (
        <div className="root">
            <main>                
                <Header />   
                <div className="container"> 
                    <div className="commonPagesContentWrap">
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>
                                    <Link to={`/catalog/${category.slug}`}>  
                                        {category.name}
                                    </Link>
                                </li> 
                                /
                                <li>{product.name}</li>                                
                            </ul>
                        </div>
                        <div className="productDetails">                        
                            <a href={`/uploads/products/${product.image}`} data-fancybox="group">
                                <img src={`/uploads/products/${product.image}`} className="cartImageMain" alt='' />
                            </a>                        
                            <div className="productDetailsContent">                                   
                                <h1>{product.name}</h1>
                                <div className="productDetailsContentWrap">
                                {product.status === 0 ? <div className="productInstock">В наличии</div> : <div className="productOutInstock">Нет в наличии</div>}
                                    <div className="productDetailsLeft">                                    
                                        <div className="productDetailsBrand"><strong>Артикул: </strong>{product.SKU}</div>
                                        <div className="productDetailsBrand"><strong>Бренд: </strong>{product.brand}</div>
                                        <div className="productDetailsBrand"><strong>Тип товара: </strong>{product.type}</div>
                                        <div className="productDetailsBrand"><strong>Цвет: </strong>{product.color}</div>
                                        {productAttr.material === null ? '' : <div className="productDetailsBrand"><strong>Материал: </strong>{productAttr.material}</div>}
                                        {productAttr.dimeter === null ? '' : <div className="productDetailsBrand"><strong>Диаметр: </strong>{productAttr.dimeter}</div>}
                                        {productAttr.volume === null ? '' : <div className="productDetailsBrand"><strong>Объем: </strong>{productAttr.volume}</div>}
                                        {productAttr.height === null ? '' : <div className="productDetailsBrand"><strong>Высота: </strong>{productAttr.height}</div>}
                                        {productAttr.width === null ? '' : <div className="productDetailsBrand"><strong>Ширина: </strong>{productAttr.width}</div>}
                                        {productAttr.length === null ? '' : <div className="productDetailsBrand"><strong>Длина: </strong>{productAttr.length}</div>}
                                        {productAttr.size === null ? '' : <div className="productDetailsBrand"><strong>Размер: </strong>{productAttr.size}</div>}

                                        <div className="productDetailsRemark">
                                            {product.remark ? <div className="productImgRemark">{product.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                                        </div>                                    
                                    </div>
                                    <div className="productDetailsRight">
                                        {product.selling_price
                                        ?
                                        <div>
                                            <div className="homeProductItemPrice red">Цена: <strong>{parseInt(product.selling_price * qty)}</strong>₸<span>{product.selling_price}₸ / шт.</span></div>
                                            <div className="productDetailsSPriceSale">Старая цена: <strong>{product.price} <span>₸</span></strong> / шт.</div>
                                        </div>
                                        :
                                        <div className="productDetailsSPrice">Цена: <strong>{parseInt(product.price * qty)}</strong>₸ <span>{product.price} ₸ / шт.</span> </div>
                                        }
                                        <div className="productUnitWrap">
                                            {product.unit_up === null 
                                            ? '' 
                                            :<button className={`productUnitUp ${activeUp ? 'active' : ''}`} onClick={() => unitHandlerUp(product.unit_up)}>Упаковка ({product.unit_up} шт.)</button>}
                                            {product.unit_kor === null 
                                            ? '' 
                                            : <button className={`productUnitKor ${activeKor ? 'active' : ''}`} onClick={() =>  unitHandlerKor(product.unit_kor)}>Коробка ({product.unit_kor} шт.)</button>}
                                        </div>
                                        <div className="productQty">Количество:</div>
                                        <div className="quantity-input">
                                            <div className="cartProdQty">
                                                <button className="cartMinus" onClick={handleDecrement}>-</button>
                                                <input
                                                    disabled
                                                    value={qty}
                                                    className="cartCountInp"
                                                    type={'text'}
                                                />
                                                <button className="cartPlus" onClick={handleIncrement}>+</button>
                                            </div>
                                        </div>     
                                                                    
                                    </div>
                                    {product.description ? <div className="productDetailsDescr">{product.description}</div> :  '' } 
                                    <div className="productCartBtns">
                                        <button className="productCartBtn" onClick={() => handleAddToCart(product, qty, currentValue)} >
                                            <i className="fas fa-shopping-bag" aria-hidden="true"></i>Купить
                                        </button>
                                        <button className="productOneClickBtn" onClick={() => setOpenModal(true)} >
                                            <i className="fa-solid fa-hand-point-up productFavBtnI"></i>Купить в 1 клик
                                        </button>
                                        {openModal && <Modal closeModal={setOpenModal} productName={product.name} />}                                        
                                    </div>
                                    <button className="productFavBtn" onClick={() => handleAddToFav(product)}>
                                        {isItemInFavourite
                                            ? <span className="favDelete"><i className="fas fa-heart" aria-hidden="true"></i>Убрать из избранного</span>
                                            : <span><i className="fas fa-heart" aria-hidden="true"></i>В избранное</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {collection === ""
                     ?   
                     <div></div>               
                    
                    :
                        <div className="detailsCollectWraper">
                            <h2>Коллекция</h2>
                            <div className="detailsCollectionWrap">
                                <div className="collectionItem">
                                    <img src={`/uploads/products/${collection[0].image}`} width='200' height='200' alt='' />
                                    <div className="collectionItemContent">
                                        <h3>{collection[0].name}</h3>
                                        <div className="homeProductItemPrice"><strong>{parseInt(collectionPrice1 * collectionQty)}</strong>₸<span>{collection[0].price}₸ / шт.</span></div>
                                    </div>                                
                                </div>
                                <span>+</span>
                                <div className="collectionItem">
                                    <img src={`/uploads/products/${collection[1].image}`} width='200' height='200' alt='' />
                                    <div className="collectionItemContent">
                                        <h3>{collection[1].name}</h3>
                                        <div className="homeProductItemPrice"><strong>{parseInt(collectionPrice2 * collectionQty)}</strong>₸<span>{collection[1].price}₸ / шт.</span></div>
                                    </div>
                                </div>
                                <span>=</span>
                                <div className="collectionCalc">
                                    <div className="cartProdQty">
                                        <button className="cartMinus" onClick={handleDecrementCollection}>-</button>
                                        <input
                                            disabled
                                            value={collectionQty}
                                            className="cartCountInp"
                                            type={'text'}
                                        />
                                        <button className="cartPlus" onClick={handleIncrementCollection}>+</button>
                                    </div>
                                    <button className="collectionBtn" onClick={() => handleAddToCartCollect()}>Купить коллекцию</button>
                                    <div className="collectionSumm"><span>Итог: </span>{(collectionPrice1 * collectionQty) + (collectionPrice2 * collectionQty)}</div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="relatedWrap">
                        <h2>Рекомендуемые товары</h2>
                        <div className="related">
                            {showProductList}
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>                
            </main>
            <Footer />
            {openQuick && <QuickModal closeModal={setOpenQuick} product_slug={quickProduct} category_slug={quickCategory} />}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default ProductDetails;