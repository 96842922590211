import Home from '../frontend/pages/Home';
import About from '../frontend/pages/About';
import Contacts from '../frontend/pages/Contacts';
import Brands from '../frontend/pages/Brands';
import Delivery from '../frontend/pages/Delivery';
import News from '../frontend/pages/News';
import Hits from '../frontend/pages/Hits';
import Sale from '../frontend/pages/Sale';
import Register from '../frontend/auth/Register';
import Login from '../frontend/auth/Login';
import Page404 from '../components/errors/Page404';
import Page403 from '../components/errors/Page403';
import Categories from '../frontend/pages/Categories';
import OneCategory from '../frontend/pages/OneCategory';
import ProductDetails from '../components/frontend/ProductDetails';
import Cart from '../frontend/pages/Cart';
import Articles from '../frontend/pages/Articles';
import Favourite from '../frontend/pages/Favourite';
import Compare from '../frontend/pages/Compare';
import Checkout from '../frontend/pages/Checkout';
import DashBoard from '../frontend/pages/DashBoard';
import OneOrder from '../frontend/pages/OneOrder';
import SearchResult from '../frontend/pages/SearchResult';
import OneBrand from '../frontend/pages/OneBrand';
import UserPassword from '../frontend/pages/UserPassword';
import UserProfile from '../frontend/pages/UserProfile';
import ForgotPassword from '../frontend/auth/ForgotPassword';



const publicRoutesList = [
    { path: '/', exact: true, name:'Home', component: Home},
    { path: '/cart', exact: true, name:'Cart', component: Cart},
    { path: '/dashboard', exact: true, name:'DashBoard', component: DashBoard},
    { path: '/order/:id', exact: true, name:'OneOrder', component: OneOrder},
    { path: '/checkout', exact: true, name:'Checkout', component: Checkout},
    { path: '/favourite', exact: true, name:'Favourite', component: Favourite},
    { path: '/articles', exact: true, name:'Articles', component: Articles},
    { path: '/compare', exact: true, name:'Compare', component: Compare},
    { path: '/about', exact: true, name:'About', component: About},
    { path: '/contacts', exact: true, name:'Contacts', component: Contacts},
    { path: '/forgot-password', exact: true, name:'ForgotPassword', component: ForgotPassword},
    { path: '/brands', exact: true, name:'Brands', component: Brands},
    { path: '/delivery', exact: true, name:'Delivery', component: Delivery},
    { path: '/news', exact: true, name:'News', component: News},
    { path: '/hits', exact: true, name:'Hits', component: Hits},
    { path: '/sale', exact: true, name:'Sale', component: Sale},
    { path: '/404', exact: true, name:'Page404', component: Page404},
    { path: '/403', exact: true, name:'Page403', component: Page403},
    { path: '/login', exact: true, name:'Login', component: Login},
    { path: '/register', exact: true, name:'Register', component: Register},
    { path: '/categories', exact: true, name:'Categories', component: Categories},
    { path: '/catalog/:slug', exact: true, name:'OneCategory', component: OneCategory},
    { path: '/catalog/:category/:product', exact: true, name:'ProductDetails', component: ProductDetails},
    { path: '/searchresult/:searchWord', exact: true, name:'SearchResult', component: SearchResult},
    { path: '/brand/:brand', exact: true, name:'OneBrand', component: OneBrand},
    { path: '/dashboard/password-update', exact: true, name:'UserPassword', component: UserPassword},
    { path: '/dashboard/profile', exact: true, name:'UserProfile', component: UserProfile},
];



export default publicRoutesList;