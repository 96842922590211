import React, { useState, useEffect } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ForgotPassword(){
    useEffect(() =>{
        document.title = 'Авторизация пользователя - Одноразовая посуда в Алматы TGR Market';
    }, [])


    const [email, setEmail] = useState({
        email: '',
    });

    const [passInput, setPassInput] = useState({
        token: '',
        password: '',
    });

    const handleInput = (e) => {
        e.persist();
        setEmail({ ...email, [e.target.name]: e.target.value });
    }

    const handleInputPass = (e) => {
        e.persist();
        setPassInput({ ...passInput, [e.target.name]: e.target.value });
    }

    const [page, setPage] = useState(0);

    const FormTitle = ['Введите Ваш email для восстановления пароля:', 'Введите код для смены пароля:'];

    const passSubmit = (e) => {
        e.preventDefault();

        const data = {
            token: passInput.token,
            password: passInput.password,
        };

        axios.post(`/api/password/reset`, data).then(res=>{
            if(res.data.status === 200){
                    toast.success('Пароль успешно изменен!', {
                    position:'top-center',
                });              
            } else if (res.data.status === 422) {
                toast.error('Вы ввели не правильный код!', {
                    position:'top-center',
                });
            }
        });
    } 

    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: email.email,
        };

        axios.post(`/api/password/email`, data).then(res=>{
            if(res.data.status === 200){
                    toast.success('Мы отправили на вашу почту код!', {
                    position:'top-center',
                });   
                setPage(1);           
            } else if (res.data.status === 422) {
                toast.error('Такой пользователь не зарегистрирован в системе!', {
                    position:'top-center',
                });
            }
        });
    } 

    const DisplayForm = () => {
        if(page === 1){
            return (
                <form onSubmit={passSubmit} autoComplete="off">
                    <fieldset className="inputField">
                        <i class="fa-solid fa-envelope"></i>
                        <input 
                            type="text" 
                            required
                            name="token" 
                            onChange={handleInputPass} 
                            value={passInput.token}
                            placeholder="Введите код"
                            className="modalInput" 
                        />
                    </fieldset>   
                    <fieldset className="inputField">
                        <i class="fa-solid fa-envelope"></i>
                        <input 
                            type="text"
                            required
                            name="password" 
                            onChange={handleInputPass} 
                            value={passInput.password}
                            placeholder="Введите пароль"
                            className="modalInput" 
                        />
                    </fieldset> 
                    <fieldset className="inputField">
                        <i class="fa-solid fa-envelope"></i>
                        <input 
                            type="text" 
                            required
                            name="password_confirmation"
                            placeholder="Повторите пароль"
                            className="modalInput" 
                        />
                    </fieldset>                          
                    <div className="formMainAuthField">
                        <button type="submit"  className="modalInputBtn">Сменить пароль</button>
                    </div>
                </form>
            );
        } else {
            return (
                <form onSubmit={loginSubmit} autoComplete="off">
                    <fieldset className="inputField">
                        <i class="fa-solid fa-envelope"></i>
                        <input 
                            type="email" 
                            required 
                            name="email" 
                            onChange={handleInput} 
                            value={email.email}
                            placeholder="Ваш email"
                            className="modalInput" 
                        />
                    </fieldset>                            
                    <div className="formMainAuthField">
                        <button type="submit"  className="modalInputBtn">Восстановить пароль</button>
                    </div>
                </form>
            );
        }
    }   


    return (
        <div className="root">
            <main>
                <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Восстановление пароля</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Восстановление пароля</li>                               
                            </ul>
                        </div>
                        <div className='commonPagesContent'>
                            <h6>{ FormTitle[page] }</h6>                            
                            {DisplayForm()}
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
            <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
        </div>
    );
}

export default ForgotPassword;