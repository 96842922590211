import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from 'sweetalert';


function About() {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aboutInput, setAbout] = useState('');


  useEffect(() => {
    axios.get(`/api/view-settings`).then(res => {
      if (res.data.status === 200) {
        setSettings(res.data.setting);
      }
      setLoading(false);
    }).catch();
  }, []);

  const handleInput = (e) => {
    e.persist();
    setAbout({ ...aboutInput, [e.target.name]: e.target.value });
  }


  const updateAbout = (e) => {
    e.preventDefault();
    const data = aboutInput;

    axios.put(`/api/update-setting`, data).then(res => {
      if (res.data.status === 200) {
        swal('Секция успешно обновлена', res.data.message, 'success');
      }
    });
  }


  if (loading) {
    return <h4>Загрузка контента...</h4>
  } else {
    return (
      <div className="container-fluid px-4">
        <h1 className="mt-4 mb-5">Раздел о компании</h1>
        <form onSubmit={updateAbout} id="BRAND_FORM">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

              <div className="form-group mb-3">
                <textarea type="text" required name="name" onChange={handleInput} value={aboutInput.settings} className="form-control"></textarea>
              </div>

            </div>
          </div>
          <button type="submit" className="btn btn-primary px-4 float-end">Обновить</button>
        </form>
      </div>
    )
  }
}

export default About
