import React, { useEffect, useState } from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addToFav } from "../../features/favSlice";
import { useDispatch } from 'react-redux';
import { addToCart } from "../../features/cartSlice";
import Skeleton from "./skeleton";
import CategoryImage from "./CategoryImage";
import QuickModal from "../../components/frontend/Modal/QuickModal";


function SearchResult (props) {
    const [openQuick, setOpenQuick] = useState(false);
    const [quickProduct, setQuickProduct] = useState('');
    const [quickCategory, setQuickCategory] = useState('');   

    const dispatch = useDispatch();
    const handleAddToCart = (item, qty, whichUnit) => {
        const newItem = {...item, quantity: qty, whichUnit: whichUnit};                
        dispatch(addToCart(newItem))
    }
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [searchWord, setSearchWord] = useState('');
    const [searchError, setSearchError] = useState('');
    const [productCalc, setProductCalc] = useState([]); //Products calc in one brand
    
    useEffect(() => {
        setSearchWord(props.match.params.searchWord);
        
        axios.get(`/api/searchresult/${searchWord}`).then(res => {
            if (res.data.status === 200) {
                setProduct(res.data.products);
                setProductCalc(res.data.products.length);
                setLoading(false);
                setSearchError('');
            } else if(res.data.status === 404){
                setLoading(false);
                setProduct([]);
                setSearchError(res.data.message)
            }
        });
    }, [props.match.params, searchWord]);

    const handleIncrement = (index, qty) => {   
        const newItems = [...product];
        newItems[index].qty = newItems[index].qty + qty;
        newItems[index].quantity = qty;
        setProduct(newItems);
    }

    const handleDecrement = (index, qty) => {         
        const newItems = [...product];
        if(newItems[index].qty > qty){
            newItems[index].qty = newItems[index].qty - qty;
            newItems[index].quantity = qty;
            setProduct(newItems);            
        }
    }

    const handleSetQty = (type, idx) => {
        const newItems = [...product];
        if(newItems[idx].quantity === type){

        }else{                  
            newItems[idx].qty = type;
            newItems[idx].quantity = type;
            setProduct(newItems);
        }        
    }
    
    const quickHandler = (product_slug, category_slug) => {
        setOpenQuick(!openQuick);
        setQuickProduct(product_slug);
        setQuickCategory(category_slug);
    }

    
    var showProductList = '';
    if(loading){
        showProductList = <Skeleton type="feed" />;
    }else if (product.length > 0){
        showProductList = product.map((item, idx) => {
            let defaultItem;
            if(item.quantity !== undefined){
                defaultItem = item.quantity; 
            }else{
                defaultItem = item.unit_up === null ? item.unit_kor : item.unit_up;
            }

            return(
                <div className="homeProductItem" key={idx}>
                    <div className="productFront">
                        <div className="quickViewProduct" onClick={() => quickHandler(item.searchSlug, item.searchCategory.slug)}><i class="fa-sharp fa-solid fa-eye"></i>Быстрый просмотр</div> 
                        <div className="homeProductItemImg">
                            <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}>
                                <CategoryImage image={item.image} title={item.name} qty={item.qty}/>
                            </Link>
                            {item.remark ? <div className="productImgRemark">{item.remark === 'sale' ? <p>Акция</p> : <span>Хит продаж</span>}</div> : <div className="productImgRemark"></div>}
                        </div>
                        <div className="homeProductItemInfo">
                            {item.selling_price
                            ?
                            <div>
                                <div className="homeProductItemPrice red"><strong>{parseInt(item.selling_price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.selling_price}₸ / шт.</span></div>
                                <div className="productDetailsSPriceSale"><strong>{item.price} <span>₸</span></strong> / шт.</div>
                            </div>
                            :
                            <div className="productDetailsSPrice"><strong>{parseInt(item.price * (item.qty === 0 ? defaultItem : item.qty))}</strong>₸ <span>{item.price}₸ / шт.</span> </div>
                            }
                        </div>
                        <Link to={`/catalog/${item.searchCategory.slug}/${item.searchSlug}`}><h2 className="productH2">{item.name}</h2></Link>
                        <div className='cartUnitType'>
                            {item.unit_up === null ? '' :<button className={`${item.unit_up === defaultItem ? 'active' : ''}`} onClick={() => handleSetQty(item.unit_up, idx)}>уп. ({item.unit_up} шт.)</button>}
                            {item.unit_kor === null ? '' : <button className={`${item.unit_kor === defaultItem ? 'active' : ''}`} onClick={() =>  handleSetQty(item.unit_kor, idx)}>кор. ({item.unit_kor} шт.)</button>}
                        </div>
                        <div className="productQty">
                            <button className="cartMinus" onClick={() =>  handleDecrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>-</button>
                            <div>
                                {item.qty === 0 ? item.qty = defaultItem : item.qty}
                            </div>
                        
                            <button className="cartPlus" onClick={() =>  handleIncrement(idx, item.quantity === undefined ? defaultItem : item.quantity)}>+</button>
                        </div>

                        <div className="catProductBtn">
                            <button
                                className="catProdAdd cartBtnProduct"
                                onClick={() => handleAddToCart(item, item.qty, item.quantity === undefined ? defaultItem : item.quantity)}
                            ><i className="fa-solid fa-basket-shopping"></i> Купить</button>
                            <button
                                onClick={() => handleAddToFav(item)}
                                className="catProdAdd favBtnProduct"
                            ><i className="fa-sharp fa-solid fa-star"></i></button>
                        </div>
                        <div className="productBackground"></div>
                    </div>
                </div>       
            ) //End return
        });
    }
    
    return (
        <div className="root">
        <main>
            <Header />
            <div className="container">   
                <div className="oneCategoryWrap">
                    <h1><p>Результаты поиска: {searchWord}</p><span>Товаров: {productCalc}</span></h1>
                    <div className="categoryNavigatinoWrap">
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Результаты поиска: {searchWord}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="searchContentWrap">
                        {showProductList ? showProductList : <h2>{searchError}</h2>  }                        
                    </div>
                </div>
            </div>
            <div className="hFooter"></div>                
        </main>
        <Footer />
        {openQuick && <QuickModal closeModal={setOpenQuick} product_slug={quickProduct} category_slug={quickCategory} />}
        <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    </div>
    )
}

export default SearchResult