import React from "react";
import Header from '../../components/frontend/Header';
import Footer from '../../components/frontend/Footer';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from "../../features/cartSlice";
import { addToFav } from "../../features/favSlice";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Favourite(){
    const favourite = useSelector ((state) => state.fav);
    const handleAddToFav = (item) => {
        dispatch(addToFav(item));
    }

    const dispatch = useDispatch();
    const handleAddToCart = (item, qty) => {
        const newItem = { ...item, quantity: qty };
        dispatch(addToCart(newItem));
        dispatch(addToFav(item));
    }

    let productList = '';
    productList = favourite.favItems.map((item, idx) => {
        return (
            <div className="homeProductItem" key={idx}>
                <div className="productFront">
                    <div className="homeProductItemImg">
                    <Link to={`catalog/${item.searchCategory.slug}/${item.slug}`}>
                        <img src={`/uploads/products/${item.image}`} className="productImage" alt={item.title}  height={230} width={230} />
                    </Link>
                    </div>
                    <Link to={`catalog/${item.searchCategory.slug}/${item.slug}`}><h2>{item.name}</h2></Link>
                    <div className="homeProductItemInfo">
                        <div className="homeProductItemCode">Код: {item.SKU}</div>
                        <div className="homeProductItemBrand">Бренд: {item.brand}</div>
                        <div className="homeProductItemPrice">{item.price.split('.')[0]} ₸ / шт.</div>                    
                    </div>
                    <div class="favProductBtn">
                        <button className="favRemoveBtn" onClick={() => handleAddToFav(item)}><i className="fas fa-trash-alt"></i></button>
                        <div className="favMoveToCart" onClick={() => handleAddToCart(item, item.qty, item.unit_up === null ? item.unit_kor : item.unit_up)}>Переместить в корзину</div>
                    </div>
                </div>             
            </div>
        )
    });

    return (
        <div className="root">
            <main>
            <Header />
                <div className="container">
                    <div className="commonPagesContentWrap">
                        <h1>Избранное</h1>
                        <div className="bread">
                            <ul>
                                <li><Link to="/">Главная</Link></li>
                                /
                                <li>Избранное</li>                               
                            </ul>
                        </div>
                        <div className="favContentWrap">
                            {favourite.favItems.length === 0 
                            ? 
                            <div className="wishlistWrap"><h2>В избранном ничего нет</h2><i className="fas fa-heart-broken" aria-hidden="true"></i><Link to="/">Начать покупки</Link></div> 
                            :
                            <div className="wishlistContent">{productList}</div> }
                        </div>
                    </div>
                </div>
                <div className="hFooter"></div>
            </main>
            <Footer />
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Favourite;